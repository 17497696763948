import Vue from 'vue';
import Router from 'vue-router';
import {
    DEFAULT_LOCALE,
    loadLanguageAsync,
    validLocale,
} from '@/lang';
import EventBus from '@/eventBus';
import { API_CRITICAL_ERROR } from '@/constants';
import Map from './views/Map.vue';
import LocaleRouter from './views/LocaleRouter.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            // root path: /en/ or /fr/
            path: '/:locale',
            component: LocaleRouter,

            // subroutes after a valid locale
            children: [
                {
                    path: '',
                    name: 'home',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
                },
                {
                    path: 'map',
                    name: 'map',
                    component: Map,
                },
                {
                    path: 'methods',
                    name: 'methods',
                    component: () => import(/* webpackChunkName: "home" */ './views/InternalPage.vue'),
                },
                {
                    path: 'our-research',
                    name: 'our-research',
                    component: () => import(/* webpackChunkName: "home" */ './views/InternalPage.vue'),
                },
                {
                    // for redirect invalid paths to locale's home
                    path: '*',
                    beforeEnter: (to, from, next) => {
                        next({ name: 'home', params: { locale: to.params.locale } });
                    },
                },
            ],
        },

        // fallback any other routes (ie, 404s) redirect to the default locale's homepage
        {
            path: '*',
            redirect: { name: 'home', params: { locale: DEFAULT_LOCALE } },
        },
    ],
});

// fetch localized site content from the CMS on demand
router.beforeEach(async (to, from, next) => {
    try {
        const { locale } = to.params;

        // redirect home for invalid locales
        if (!validLocale(locale)) {
            next({ name: 'home', params: { locale: DEFAULT_LOCALE } });
            return;
        }

        // fetch locale content from CMS
        EventBus.$emit('start-route-loading');
        const vm = router.app;
        await loadLanguageAsync.call(vm, locale);
        EventBus.$emit('end-route-loading');

        next();
    } catch (error) {
        EventBus.$emit('error', error, API_CRITICAL_ERROR, true);
    }
});

export default router;
