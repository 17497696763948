<template>
    <div class="is-flex is-flex-direction-column">
        <div class="map-search is-flex is-flex-direction-row">
            <div
                v-if="isMobile"
                class="box"
            >
                <b-button
                    class="my-location-button"
                    icon-left="menu"
                    @click="onClick"
                >
                    <slot />
                </b-button>
            </div>
            <div class="box search-input-wrap">
                <geocoder-search
                    class="map-search-input"
                    @select="emitCoords"
                />
            </div>
            <div class="box gps-wrap">
                <b-tooltip
                    :label="$t('home.buttons.geolocationTooltip')"
                    type="is-tooltip"
                    position="is-left"
                >
                    <my-location-button
                        @success="emitCoords"
                        @error="myLocationError"
                    />
                </b-tooltip>
            </div>
        </div>
        <h1
            v-if="address !== null"
            class="title search-location"
        >
            {{ address.firstLine }}
        </h1>
    </div>
</template>

<script>
import GeocoderSearch from '@/components/GeocoderSearch.vue';
import MyLocationButton from '@/components/MyLocationButton.vue';
import EventBus from '@/eventBus';

export default {
    components: {
        GeocoderSearch,
        MyLocationButton,
    },

    props: {
        address: {
            type: null,
            required: true,
        },
    },
    data: () => ({
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }),

    methods: {
        /**
         * on user geolocation error, show a message
         */
        myLocationError() {
            EventBus.$emit('error', null, this.$t('errors.myLocationTryClick'));
        },

        /**
         * emit coordinates so the map can show the point
         * @param  {mapboxgl.LngLat} lngLat mapbox LngLat object
         */
        emitCoords(lngLat, address) {
            EventBus.$emit('location', lngLat, address);
        },

        onClick() {
            this.$emit('menu');
        },
    },
};
</script>

<style lang="scss">
.search-location {
    color: $primary;
    font-size: 1.2rem;
    margin-left: 1rem;
}

.map-search {
    margin: 0 1rem 0.5rem;
    @include mobile {
        margin: 0;
    }
    .box {
        margin: 0;
        padding: .25rem;

        // borderless styles on input elements
        .input,
        .button {
            border: none;
            border-color: transparent;
        }
    }
    .search-input-wrap {
        flex: 1;
    }
}

@include tablet {
    .map-search {
        margin: 0 1rem 1rem;
    }
}
</style>
