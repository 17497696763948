<template>
    <div
        id="metrics"
        class="map-filters"
    >
        <div
            class="header-section"
        >
            <h1
                v-if="!isMobile"
                class="title is-size-7-mobile is-size-6-tablet"
            >
                {{ $t('layerControl.header.expanded') }}
            </h1>
        </div>
        <div
            class="layers-content is-size-7"
        >
            <b-collapse
                v-for="(layer, index) of themeList"
                :key="layer"
                :aria-id="'contentIdForA11y5-' + index"
                class="card"
                animation="slide"
                :native-value="layer"
                :open="isOpen == index"
                @open="() => {
                    myIndex = index;
                    isOpen = index;
                    setLayerVisibility(layer)
                }"
            >
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        :aria-controls="'contentIdForA11y5-' + index"
                        :aria-expanded="props.open"
                    >
                        <p class="card-header-title">
                            {{ $t(`metrics.${layer}.title`) }}
                            <a
                                v-if="isOpen == index"
                                id="learn-more-link"
                                :href="$t(`metrics.${layer}.link`)"
                                target="_blank"
                                @click="gaEventTracking('text-link')"
                            >
                                {{ $t('metrics.learnMore') }}
                            </a>
                        </p>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'menu-up' : 'menu-down'"
                            />
                        </a>
                    </div>
                </template>
                <div
                    v-for="subLayer in subThemeList[layer]"
                    :key="subLayer.name"
                >
                    <div class="card-content metric-score">
                        <b-radio
                            v-model="radioSelection"
                            :name="subLayer.name"
                            :native-value="subLayer.name"
                            @input="setSublayerVisibility(subLayer.name, true)"
                        >
                            {{ $t(`metrics.${layer}.subLayers.${subLayer.name}`) }}
                        </b-radio>
                        <div
                            class="sub-layer-score"
                        >
                            <div
                                class="sub-layer-score-bar-container"
                            >
                                <div
                                    v-if="dimensionScores"
                                    class="sub-layer-score-bar"
                                    :style="{width: `${dimensionScores[subLayer.name] * 10}`
                                        + '%', backgroundColor: subLayer.color}"
                                />
                            </div>
                            <div
                                v-if="dimensionScores && subLayer"
                                class="sub-layer-score-value"
                            >
                                {{ dimensionScores[subLayer.name] }}
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapFilters',

    props: {
        map: {
            type: Object,
            required: true,
        },

        selected: {
            type: String,
            required: true,
        },

        dimensionScores: {
            type: Object,
            default: () => {},
        },

        metrics: {
            type: Array,
            required: true,
        },
        colors: {
            type: Array,
            required: true,
        },

    },

    data: () => ({
        isOpen: false,
        myIndex: 0,
        // mobile device detection
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        currentSource: false,
    }),

    computed: {
        radioSelection: {
            get() {
                return this.selected;
            },

            set(subLayerId) {
                this.$emit('input', subLayerId, this.metrics.find(t => t.table_name === subLayerId).theme);
            },
        },

        themeList: {
            get() {
                const myThemes = [];
                this.metrics.forEach((metric) => {
                    if (!myThemes.includes(metric.theme)) myThemes.push(metric.theme);
                });
                return myThemes;
            },
        },

        subThemeList: {
            get() {
                const myThemes = {};
                this.metrics.forEach((metric) => {
                    if (!(`${metric.theme}` in myThemes)) myThemes[metric.theme] = [];
                    myThemes[metric.theme].push({
                        name: metric.table_name,
                        color: metric.colour,
                        mapbox_layer_name: metric.mapbox_layer_name,
                        theme: metric.theme,
                    });
                });
                return myThemes;
            },
        },
    },

    // build layers array from global metricsArray
    async created() {
        try {
            await this.metrics;
        } catch (e) {
            this.$buefy.snackbar.open({
                message: this.$t('errors.tableError'),
                type: 'is-warning',
            });
        }

        this.radioSelection = this.subThemeList[this.themeList[0]][0].name;
    },

    mounted() {
        this.$emit('loaded');
        this.setSublayerVisibility(this.selected);
    },

    methods: {
        /**
         * Converts a hex colour string to a mapbox colour-like array
         *
         * @param {string} hexCode - A Hexcode colour string like '#6699cc'
         * @returns an array suitable for use as a colour in mapbox expressions
         */
        convertToRGBArray(hexCode) {
            // expects a string like #6699cc, remove the hash and conver to an array like ['rgb', 102, 153, 204]
            const rgbArray = hexCode.replace(/^#/, '').match(/.{1,2}/g).map(val => parseInt(val, 16));
            rgbArray.unshift('rgb');
            return rgbArray;
        },

        /**
         * change layer group and its associated subLayers visibilities
         *
         * @param {string} selectedLayer - the currently selected layer details
         */
        setLayerVisibility(selectedLayer) {
            if (selectedLayer) {
                // set the first child subMetric as the chosen selectedMetric
                this.radioSelection = this.subThemeList[selectedLayer][0].name;
                this.setSublayerVisibility(this.subThemeList[selectedLayer][0].name, true);
            }
        },

        /**
         * change subLayer map layer id visibilities
         *
         * @param {string} subLayerId - subLayer id
         * @param {boolean} visible - true shows the layer, false hides
         */
        setSublayerVisibility(subLayerId) {
            const colourMap = this.colors.find(x => x.id === subLayerId);
            // add a source, remove the current source, and set the PaintProperty
            const mapSource = `canue.${subLayerId.replace('place_', '')}`;
            const sourceLayer = `${subLayerId.replace('place_', '')}_points`;
            if (this.currentSource === mapSource) {
                return;
            }
            // add a source, remove the other
            if (this.currentSource) {
                // remove this source and add the other.
                if (this.map.getSource(this.currentSource)) {
                    this.map.removeLayer('sublayer');
                    this.map.removeSource(this.currentSource);
                }
            }
            this.currentSource = mapSource;
            this.map.addSource(mapSource, {
                type: 'vector',
                url: `mapbox://${mapSource}`,
            });
            this.map.addLayer({
                id: 'sublayer',
                type: 'circle',
                source: this.currentSource,
                'source-layer': sourceLayer,
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['exponential', 1],
                        ['zoom'],
                        0,
                        1,
                        8,
                        2,
                        13.06,
                        35,
                        14.66,
                        100,
                        16.17,
                        270,
                        17.67,
                        750,
                        22,
                        10000,
                    ],
                    'circle-translate': [
                        'interpolate',
                        ['exponential', 1],
                        ['zoom'],
                        0, ['literal', [0.5, 0.5]],
                        8, ['literal', [1, 1]],
                        13.06, ['literal', [17.5, 17.5]],
                        14.66, ['literal', [50, 50]],
                        16.17, ['literal', [135, 135]],
                        17.67, ['literal', [375, 375]],
                        22, ['literal', [5000, 5000]],
                    ],
                    'circle-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'val'],
                        1,
                        this.convertToRGBArray(colourMap.stops[0]),
                        2,
                        this.convertToRGBArray(colourMap.stops[1]),
                        3,
                        this.convertToRGBArray(colourMap.stops[2]),
                        4,
                        this.convertToRGBArray(colourMap.stops[3]),
                        5,
                        this.convertToRGBArray(colourMap.stops[4]),
                        6,
                        this.convertToRGBArray(colourMap.stops[5]),
                        7,
                        this.convertToRGBArray(colourMap.stops[6]),
                        8,
                        this.convertToRGBArray(colourMap.stops[7]),
                        9,
                        this.convertToRGBArray(colourMap.stops[8]),
                        10,
                        this.convertToRGBArray(colourMap.stops[9]),
                    ],
                    'circle-blur': 1,
                    'circle-opacity': 1,
                },
            }, 'water-dobw04');
        },
    },

};
</script>

<style lang="scss">
#learn-more-link {
    font-size: 0.8em;
    margin-left: 1em;
}

.map-filters {
    // width: 14rem;
    width: 100%;
    padding: 1rem;
    margin: 0;
    font-size: 20px !important;

    .header-section {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;

        .header-title {
            flex: 1;
            margin: 0;
            padding-bottom: 1rem;
        }

        .icon-container {
            padding-top: 3px;
        }
    }

    .layers-content {
        .metric-score {
            padding: 1rem 1.5rem;
        }
    }

    .sub-layer-score {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 1.3rem;

        .sub-layer-score-bar-container {
            background-color: white;
            margin-bottom: 0;
            margin-right: 5%;
            border: 1px solid black;
            border-radius: 50px;
            height: 0.7rem;
            width: 90%;
        }

        .sub-layer-score-bar {
            height: 100%;
            border: 1px solid white;
            border-radius: 50px;
        }

        .sub-layer-score-value {
            font-weight: bold;
        }
    }

}

@include tablet {
    .map-filters {
        margin: 0;
        padding: 0.75rem;
    }
}
</style>
