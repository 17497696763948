<template>
    <div class="map-page">
        <map-search
            v-if="isMobile"
            id="map-search"
            class="map-search"
            :address="displaylocation"
            @menu="showMetricSidebar"
        />
        <div
            v-if="isMobile && mobileMenu"
            class="shademan"
            @click="showMetricSidebar"
        />
        <div
            v-if="isMobile"
            class="mobile-sidebar"
            :class="{'open': mobileMenu}"
        >
            <mobile-sidebar @close="mobileMenu = false" />
        </div>
        <div
            id="mobile-metrics-section"
            class="metrics-section"
            :class="{'open': metricsVisibility && isMobile}"
        >
            <div
                v-if="isMobile"
                class="metrics-section-toggle-inside is-flex is-justify-content-space-around mt-3"
                @click="toggleMetricsSection"
            >
                <h2 class="mobile-header">
                    {{ $t('layerControl.header.expanded') }}
                </h2>
                <b-icon
                    icon="close"
                    class="is-pulled-right"
                    pack="mdi"
                />
            </div>
            <page-header />
            <map-search
                v-if="!isMobile"
                id="map-search"
                class="map-search"
                :address="displaylocation"
            />
            <div class="nav-links single">
                <a @click="startTour()">
                    <b-icon
                        size="is-small"
                        class="tutorial-icon"
                        pack="mdi"
                        icon="information-outline"
                    />
                    {{ $t('home.buttons.tutorial') }}
                </a>
            </div>
            <div id="map-filters">
                <map-filters
                    v-if="map && selectedDimension.subMetric"
                    id="map-filters"
                    :map="map"
                    :selected="selectedDimension.subMetric"
                    :metrics="metrics"
                    :dimension-scores="dimensionScores"
                    :colors="colorGrades"
                    @close="lngLat = null; clearMapMarker(); closeResults(); "
                    @input="selectLayer"
                    @loaded="filtersLoaded"
                />
            </div>
            <community-plan-search-section />
            <email-subscribe
                id="penultimate"
                class="email-subscribe"
            />
            <partners-section
                class="partners-section"
                :partners="this.$t('footer')"
            />

            <div class="nav-links">
                <a
                    v-for="nav in this.$t('navigation')"
                    :key="nav.title"
                    :href="`${nav.url ? nav.url.url : ''}`"
                    target="_blank"
                    class="footer-link"
                >
                    {{ nav.title }}
                </a>
                <!-- {{ nav.url.slug }} -->
            </div>
        </div>
        <main
            id="healthyplace-map"
            class="map-screen flex-wrap is-flex"
        >
            <mapbox-gl-map
                id="mapbox-gl-map"
                :access-token="mapboxToken"
                :options="mapOptions"
                @click="mapClick"
                @loaded="mapLoaded"
                @moveend="mapMoveEnd"
            />
        </main>

        <b-loading
            class="results-button"
            :active="pageLoading || loadingScore"
        />

        <b-modal
            id="out-of-bounds-modal"
            :active.sync="outOfBoundsModal"
            scroll="keep"
            :width="540"
            :can-cancel="['escape', 'outside']"
        >
            <suggested-pois
                @location="locationSelected"
                @close="outOfBoundsModal = false"
            />
        </b-modal>

        <div class="map-overlay">
            <div id="map-legend">
                <map-legend
                    v-if="selectedDimension.subMetric !== null"
                    :metric="selectedDimension"
                    :metrics="metrics"
                    :dimension-scores="dimensionScores"
                    :colors="colorGrades"
                    :lng-lat="lngLat"
                    :address="displaylocation"
                />
            </div>
            <survey-prompt
                @show-survey="showSurvey"
            />
            <div
                id="metrics-button"
                class="metrics-section-toggle-outside"
                @click="toggleMetricsSection"
            >
                <p>{{ $t('toggle.label') }}</p>
            </div>
        </div>

        <locale-switcher
            class="locale-switcher"
        />

        <v-tour
            ref="healthyplace-tour"
            name="healthyplace-tour"
            :steps="(isMobile)?Object.values($t('mobileSteps')):Object.values($t('tourSteps'))"
            :options="{
                highlight: true,
                enabledButtons: {
                    buttonSkip: false,
                },
                removeInvalidSteps: false,
                labels: {
                    buttonPrevious: 'Go Back',
                    buttonNext: 'Next',
                    buttonStop: 'Finish',
                    titleTemplate: (isMobile)?$t('mobileHeader'):$t('tourHeader'),
                }
            }"
            :callbacks="{
                onStop: removeTut,
            }"
            @onbefore="tourAction"
            @onafter="tourAction"
        />
    </div>
</template>

<script>
import { Marker, LngLat } from 'mapbox-gl';
import axios from 'axios';
import turfBuffer from '@turf/buffer';
import { point as turfPoint } from '@turf/helpers';

import {
    MAPBOX_ACCESS_TOKEN,
    MAPBOX_DEFAULT_STYLE,
    API_URL,
} from '@/constants';
import EmailSubscribe from '@/components/EmailSubscribe.vue';
import MapboxGlMap from '@/components/MapboxGlMap.vue';
import MapFilters from '@/components/MapFilters.vue';
import MapLegend from '@/components/MapLegend.vue';
import MapSearch from '@/components/MapSearch.vue';
import MobileSidebar from '@/components/MobileSidebar.vue';
import PartnersSection from '@/components/PartnersSection.vue';
import PageHeader from '@/components/PageHeader';
import SuggestedPois from '@/components/SuggestedPois.vue';
import SurveyPrompt from '@/components/UserSurveyPrompt.vue';
import UserSurvey from '@/components/UserSurvey.vue';
import EventBus from '@/eventBus';
import VTour from '@/components/VTour';
import markerSvg from '@/assets/marker.svg';
import scssVars from '@/_variables.scss';
import CommunityPlanSearchSection from '../components/CommunityPlanSearchSection.vue';
import LocaleSwitcher from '../components/LocaleSwitcher.vue';

export default {
    name: 'MapPage',

    components: {
        CommunityPlanSearchSection,
        EmailSubscribe,
        LocaleSwitcher,
        MapboxGlMap,
        MapFilters,
        MapLegend,
        MapSearch,
        MobileSidebar,
        PageHeader,
        PartnersSection,
        SuggestedPois,
        SurveyPrompt,
        VTour,
    },

    data: () => ({
        showResults: false,
        map: null,
        mapMarker: null,
        selectedArea: {},
        drawType: '',
        mapOptions: {
            style: MAPBOX_DEFAULT_STYLE,
            bounds: [
                [
                    -141.591796,
                    67.575717,
                ],
                [
                    -50.2734375,
                    40.913512,
                ],
            ],
        },
        lngLat: null,
        address: null,
        locationFromMapClick: false,
        reportShared: false,
        // colors to create gradients
        colorGrades: null,
        // collection of metrics theme data
        metrics: null,
        // currently selected submetric
        selectedDimension: {
            id: null,
            subMetric: null,
        },
        // scores
        dimensionScores: null,
        // out of bounds notice modal
        outOfBoundsModal: false,
        // for mobile, hide the metrics section until toggled
        metricsVisibility: false,
        // display sidebar for mobile
        mobileMenu: false,
        // mobile device detection
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }),

    computed: {
        // mapbox token from constants or environment vars
        mapboxToken() {
            return MAPBOX_ACCESS_TOKEN;
        },

        // "loading" when the map and the async constants aren't set yet
        pageLoading() {
            return this.map === null;
        },

        // "loading" when the results are meant to be showing, but not yet loaded
        loadingScore() {
            return this.showResults && this.dimensionScores === null;
        },

        displaylocation() {
            return {
                firstLine: this.locationFirstLine,
                secondLine: this.locationSecondLine,
                lngLat: this.lngLat,
            };
        },

        locationFirstLine() {
            if (this.address) return this.address.firstLine;
            if (this.locationFromMapClick) return this.$t('report.header.selectedLocation.header');
            if (this.reportShared) return this.$t('report.header.sharedLocation.header');
            if (this.lngLat) return this.$t('report.header.geolocation.header');
            return '';
        },

        locationSecondLine() {
            if (this.address) return this.address.secondLine;
            if (this.locationFromMapClick) return this.$t('report.header.selectedLocation.subheader');
            if (this.reportShared) return this.$t('report.header.sharedLocation.subheader');
            if (this.lngLat) return this.$t('report.header.geolocation.header');
            return '';
        },

        reportUrl() {
            const params = new URLSearchParams();
            params.set('lat', this.lngLat.lat);
            params.set('lng', this.lngLat.lng);
            params.set('shared', true);
            if (this.address) {
                params.set('addressFirstLine', this.address.firstLine);
                params.set('addressSecondLine', this.address.secondLine);
            }

            const { origin } = window.location;
            const { pathname } = window.location;

            return `${origin}${pathname}?${params.toString()}`;
        },

        isMetricsSectionVisibile() {
            return this.metricsVisibility;
        },
    },

    watch: {
        selectedDimension() {
            // track analytics event
            if (this.dimensionScores) {
                window.gtag('event', 'score-report', { action: 'Score', label: `${this.selectedDimension.subMetric}: ${this.dimensionScores[this.selectedDimension.subMetric]}` });
            }
        },
    },

    async created() {
        // set the initial metric selection and get the collection of metrics and themes
        try {
            await axios.get(`${API_URL}/metrics`)
                .then((metrics) => {
                    this.colorGrades = metrics.data.colors;
                    this.metrics = metrics.data.themes;
                    this.selectedDimension.id = metrics.data.themes[0].mapbox_layer_name;
                    this.selectedDimension.subMetric = metrics.data.themes[0].table_name;
                });
        } catch (e) {
            this.$buefy.snackbar.open({
                message: this.$t('errors.tableError'),
                type: 'is-warning',
            });
        }
    },

    mounted() {
        // listen for coordinate events emitted through the event bus
        EventBus.$on('location', this.locationSelected);
        if (this.$t('analyticsCode')) {
            // use the script element that CANUE has in prismic
            const gaScript = document.createRange().createContextualFragment(this.$t('analyticsCode').trim());
            document.head.append(gaScript);
        }
    },

    beforeDestroy() {
        // remove event listeners on the EventBus
        EventBus.$off('location', this.locationSelected);
    },

    methods: {
        /**
         * apply dimension option change
         *
         * @param {String} option name (id) for the "dimension"
         */
        selectLayer(option, id) {
            this.selectedDimension.subMetric = option;
            this.selectedDimension.id = id;
        },

        /**
         * handle user clicks on the map
         * @param  {Event} ev map click event
         */
        mapClick(ev) {
            // remove any existing click markers
            this.clearMapMarker();

            // if the results box is showing already,
            // treat clicks like a "reset" and hide the results
            if (this.showResults) {
                this.closeResults();
                return;
            }

            // get lnglat and map object from the event
            const { lngLat, target: map } = ev;

            // fire the map click method
            this.showMapMarker(lngLat, map);

            this.locationFromMapClick = true;
            this.lngLat = lngLat;

            // fetch and show score
            this.getScore(lngLat);

            // track analytics event (round coordinates to 4 decimals)
            const gaLng = parseFloat(lngLat.lng).toFixed(4);
            const gaLat = parseFloat(lngLat.lat).toFixed(4);
            window.gtag('event', 'map-click', { action: 'Map', label: `${gaLng},${gaLat}` });
        },

        /**
         * show a map marker and results box for a given point
         * @param  {mapboxgl.LngLat} lngLat clicked/selected coordinates
         * @param  {mapboxgl.Map} map    mapbox gl map object
         */
        showMapMarker(lngLat, map) {
            // remove any existing click markers
            this.clearMapMarker();

            // create custom map marker element
            const div = document.createElement('img');
            div.src = markerSvg;
            div.width = 36;

            // add click marker
            this.mapMarker = new Marker({
                element: div,
                anchor: 'center',
            })
                .setLngLat(lngLat)
                .addTo(map);

            // add click marker buffer
            const point = turfPoint([lngLat.lng, lngLat.lat]);
            this.featureBuffer(point, 'user-polygon-buffer', 1);
        },

        /**
         * Add buffer map layer to Mapbox GL marker point features
         *
         * @param {GeoJSON} feature - Mapbox GL marker point features
         * @param {string} type - buffer draw feature type
         * @param {number} range - buffer draw feature range
         */
        featureBuffer(feature, type, range) {
            const featureBuffer = turfBuffer(feature, range, { units: 'kilometers' });
            this.selectedArea = featureBuffer;
            this.drawType = type;
            const layer = {
                id: this.drawType,
                type: 'line',
                source: {
                    type: 'geojson',
                    data: this.selectedArea,
                },
                paint: {
                    'line-color': scssVars.colorUserSelection,
                    'line-width': 3,
                    'line-dasharray': [2, 1],
                },
            };
            this.map.addLayer(layer);
        },

        /**
         * remove any existing click point markers
         */
        clearMapMarker() {
            if (this.mapMarker !== null) {
                this.mapMarker.remove();
            }

            // check if any buffer layers already exist
            if (this.drawType) {
                this.clearFeatureFromMap(this.drawType);
            }
        },

        /**
         * Remove specified layer from map
         *
         * @param {string} featureId - mapbox layer id
         */
        clearFeatureFromMap(featureId) {
            // remove feature layer and source from the map's style
            this.map.removeLayer(featureId);
            this.map.removeSource(featureId);
            this.drawType = '';
        },

        /**
         * hook for when the map loads
         * @param  {mapboxgl.Map} mapObj mapbox gl map object
         */
        mapLoaded(mapObj, loadEvent) {
            // store the map object in this component's data
            this.map = mapObj;

            // parse lnglat from query string, if present
            this.processQueryString();

            // fire map moveend event so the geocoder
            this.mapMoveEnd(loadEvent);
        },

        /**
         * show selected coordinates and results
         * @param  {mapboxgl.LngLat} lngLat mapbox LngLat object
         */
        goToCoords(lngLat, addressTypes = []) {
            // hide extra components first
            this.closeResults();

            // when city entered, only zoom to location
            if (addressTypes.includes('political')) {
                this.map.flyTo({
                    center: lngLat,
                    maxDuration: 3000,
                    zoom: 10,
                });
            // when address or postal code entered, get location score
            } else {
                // fire a click at these coords
                this.showMapMarker(lngLat, this.map);

                this.map.once('moveend', () => {
                    // fetch and show score
                    this.getScore(lngLat);
                });

                this.map.flyTo({
                    center: lngLat,
                    maxDuration: 3000,
                    zoom: 13,
                });
            }
        },

        /**
         * fetch a score from the API, and show the results component
         * @param  {mapboxgl.LngLat}  lngLat mapbox LngLat object for the query location
         * @param  {Boolean} [showResults=true] show the results box after fetching the score
         */
        getScore(lngLat, showResults = true) {
            this.unselectPoint();
            this.showResults = showResults;
            const { lng, lat } = lngLat;
            return axios.get(`${API_URL}/score`, {
                params: {
                    lng,
                    lat,
                },
            })
                .then(({ data }) => {
                    const { allScores } = data;
                    const scoreValues = Object.values(allScores);
                    if (scoreValues.every(score => score === null)) {
                        // if all scores are null show modal window with suggested POIs
                        this.outOfBoundsModal = true;
                        this.closeResults();
                        return null;
                    }

                    // store all dimension scores
                    this.dimensionScores = allScores;
                    return data;
                })
                .catch((e) => {
                    EventBus.$emit('error', e, this.$t('errors.apiScore'));
                    this.closeResults(false);
                });
        },

        /**
         * Call when a location has been selected. It will go to the location on the map and set the address if it's
         * available.
         *
         * @param {Object} lngLat - the coordinates of the location
         * @param {Object} address - the parts of the location's address
         */
        locationSelected(lngLat, address) {
            // using geocoder search
            if (address) {
                this.goToCoords(lngLat, address.types);
                // store selected address
                this.address = address;
            // using my location button
            } else {
                this.goToCoords(lngLat);
            }
            // store selected coordinates
            this.lngLat = lngLat;
        },

        /**
         * parse lat and lng from the query string, if possible
         * then fire a click/selection at those coordinates
         */
        processQueryString() {
            const params = new URLSearchParams(document.location.search);

            const lng = params.get('lng');
            const lat = params.get('lat');
            const addressFirstLine = params.get('addressFirstLine');
            const addressSecondLine = params.get('addressSecondLine');
            const addressTypes = params.getAll('addressTypes');
            this.reportShared = params.get('shared');

            let address;
            if (addressFirstLine || addressSecondLine) {
                address = {
                    firstLine: addressFirstLine,
                    secondLine: addressSecondLine,
                    types: addressTypes,
                };
            }

            if (lng !== null && lat !== null) {
                const lngLat = new LngLat(lng, lat);

                // clear query string from url
                window.history.replaceState({}, document.title, window.location.pathname);

                this.locationSelected(lngLat, address);
            }
        },

        /**
         * hide the results box
         */
        closeResults(unselectPoint = true) {
            // hide the results box
            this.showResults = false;

            if (unselectPoint) {
                this.lngLat = null;
                this.address = null;
            }

            this.locationFromMapClick = false;

            // also unselect any clicked point
            if (unselectPoint) {
                this.unselectPoint();
            }

            // remove any existing click markers
            this.clearMapMarker();
        },

        /**
         * reset local score vars to null
         */
        unselectPoint() {
            // reset anything that gets set in `getScore`
            this.dimensionScores = null;
        },

        /**
         * handle map move events, re-emit through EventBus
         * @param  {Event} ev mapbox event with `target`
         */
        mapMoveEnd(ev) {
            const map = ev.target;

            EventBus.$emit('map:moveend', {
                center: map.getCenter(),
                zoom: map.getZoom(),
            });
        },

        /**
         * Shows the user survey modal window.
         */
        showSurvey() {
            // pop up the user survey
            this.$buefy.modal.open({
                parent: this,
                component: UserSurvey,
                canCancel: ['outside'],
            });

            // track analytics event
            window.gtag('event', 'survey-link-popup', { action: 'Link' });
        },

        /**
         * Toggle the metrics section
         */
        toggleMetricsSection() {
            this.metricsVisibility = !this.metricsVisibility;
            return this.metricsVisibility;
        },

        showMetricSidebar() {
            this.mobileMenu = !this.mobileMenu;
        },

        /**
         * Only execute the tutorial if it's requested and the filters have loaded.
         */
        filtersLoaded() {
            // start the tutorial here if we need to. We need to wait until this stuff gets loaded
            if ((new URLSearchParams(document.location.search)).get('tut')) {
                this.startTour();
            }
        },

        startTour() {
            this.$refs['healthyplace-tour'].start();
        },

        /**
         * Removes the parameter which makes the tutorial happen so it doesn't linger.
         */
        removeTut() {
            const query = Object.assign({}, this.$route.query);
            if (query.tut) {
                // remove the query param
                this.$router.replace({ query: [] });
            }
        },

        /**
         * Execute something given a specific whitelisted set of parameters
         *
         * @param {object} params
         */
        tourAction(params) {
            // TODO: allow for parameters for the actions, if necessary
            if (['toggleMetricsSection'].includes(params.action)) {
                this.toggleMetricsSection();
            }
        },
    },
};
</script>

<style lang="scss">

.map-page {
    display: grid;

    // single-column layout
    grid-template-areas:
        'map'
        'metrics';
    grid-template-columns: 1fr;
    grid-template-rows: 70vh auto;
    row-gap: 1.5rem;

    @include desktop {
        // 3-column layout
        grid-template-areas: 'metrics map';
        grid-template-columns: min(30vw, 25rem) 1fr;
        grid-template-rows: 100vh;
        gap: 0;
        overflow-y: hidden;
    }

    #healthyplace-map {
        position: relative;
    }
    // map screen sizing
    .flex-wrap {
        flex-direction: column;
        height: 100vh;
        min-height: 20rem;

        // wrapper for map and its controls
        section.map {
            // grow/shrink while the nav-bar height stays static
            flex: 1;

            .map-search-wrap {
                position: absolute;
                top: 10px; // map control spacing
                left: 0;
                right: 0;
                z-index: 5; // above the map

                // click through the wrapper div, but not the children
                pointer-events: none;
                > * {
                    pointer-events: auto;
                }

                // enforce a max-width on the map search component
                .map-search {
                    max-width: 55rem;
                }

            }
        }

    }

    .mapbox-gl-map-component {
        grid-area: map;
        flex: 1 1 100%;

        .foundry-spatial-logo {
            height: 1.3em;
            vertical-align: text-bottom;
        }

        .mapboxgl-ctrl-group {
            margin: 65px 16px 0 0;
        }

        @include desktop {
            .mapboxgl-ctrl-group {
                margin: 10px 10px 0 0;
            }
        }
        @include mobile {
            overflow-y: hidden;
        }
    }

    // map screen positioning
    .map-screen {
        position: sticky;
        top: 0;
    }

    // results positioning
    section.results {
        // peek above the fold
        margin-top: -8rem;
        border-radius: 1.5rem 1.5rem 0 0;
    }
}

.mapboxgl-ctrl-bottom-left {
    @include mobile {
        position: absolute;
        top: 5rem;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }
}

.mapboxgl-ctrl-bottom-right {
    @include mobile {
        display: none;
        margin-bottom: 16.5rem;
    }
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin-right: 1.1rem;
}

.mapboxgl-ctrl-attrib-button {
    @include mobile {
        top: 0;
    }
}

.metrics-section {
    background-color: white;
    max-height: 100vh;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

    .email-subscribe {
        padding: 1rem;
    }

    @include mobile {
        position: absolute;
        transition-duration: 0.2s !important;
        z-index: 10;
        overflow-x: hidden;
        height: 100vh;
        padding-bottom: 1rem;
        left: -150%;
        box-shadow: 0 0 0.5rem rgba(black, 0.1);

        .mobile-header {
            color: $primary;
            font-weight: bold;
        }

        #header,
        .is-flex.is-flex-direction-column.map-search,
        .community-search,
        .email-subscribe,
        .hp-section.partners.partners-section,
        .nav-links {
            display: none;
        }
    }
}

@include mobile {
    #map-search {
        position: fixed;
        top: 1rem;
        display: inline-block;
        width: 100vw;
        z-index: 2;
        margin-left: 0;
    }

    .title.search-location {
        display: none;
    }


    .metrics-section.open {
        left: 0 !important;
        transition-duration: 0.2s !important;
    }

    .mobile-sidebar.open {
        left: 0 !important;
        transition-duration: 0.2s !important;
    }
}

.map-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    @include desktop {
        right: 1rem;
    }
    height: 100vh;
    // allows the user to drag the map behind the invisible legend div
    pointer-events: none;

    @include mobile {
        flex-direction: column-reverse;
        // top: 1.5em;
        min-height: -webkit-fill-available;
        height: 90vh;
    }

    .metrics-section-toggle-outside {
        display: none;

        @include mobile {
            display: block;
            position: absolute;
            background-color: $primary-invert;
            color: $primary;
            font-weight: bold;
            top: 9rem;
            padding: 0.25rem 0.75rem;
            margin-left: 0.5em;
            border-radius: 50px;
            z-index: 2;
            pointer-events: all;
            border: 2px solid $primary;
        }
    }

    .metrics-section-toggle-inside {
        display: none;

        @include mobile {
            padding-top: 2rem;
            z-index: 2;
            pointer-events: all;
        }
    }
}

#penultimate {
    flex-grow: 1;
}

.nav-links {
    align-items: flex-end;
    background-color: $light;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    padding: 10px 20px 10px 20px;
    width: 100%;

    &.single {
        grid-template-columns: 1fr;
    }

    .footer-link {
        text-align: center;
    }
}

.locale-switcher {
    position: absolute;
    right: -0.5rem;
    bottom: 7rem;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 1rem;

    @include mobile {
        right: 0;
        top: 5rem;
        margin-bottom: 18rem;
    }
}

.mobile-sidebar {
    background-color: white;
    position: absolute;
    left: -150%;
    top: 0;
    position: absolute;
    transition-duration: 0.2s !important;
    z-index: 9;
}

.shademan {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 8;
}
</style>
