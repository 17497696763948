<template>
    <div id="app">
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view @i18n-load.once="() => showUserAgreement()" />
        </transition>
        <b-loading
            class="loading-route"
            animation="fadeOut"
            :active="loadingRoute"
            is-full-page
        />
    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@icon/maki-icons/maki-icons.css';
import EventBus from '@/eventBus';
import UserAgreementVue from './components/UserAgreement.vue';

export default {
    name: 'App',

    data: () => ({
        // show fullscreen loading overlay
        loadingRoute: false,
        agreed: false,
    }),

    created() {
        // handle errors emitted from other components
        EventBus.$on('error', this.onError);

        // handle loading signals from the router / view components
        EventBus.$on('start-route-loading', () => { this.loadingRoute = true; });
        EventBus.$on('end-route-loading', () => { this.loadingRoute = false; });
    },

    mounted() {
        // initialize animation library
        AOS.init();
    },

    methods: {
        /**
         * handle "error" events emitted through the EventBus
         * display a message to the user,
         * with an optional action when they click to dismiss it
         *
         * @param  {Error}   err               Exception object, will be printed to the console
         * @param  {String}  message           Message to display for the user
         * @param  {Boolean} [critical=false]  If true, force the page to refresh when the user clicks "OK"
         * @param  {Object}  [extraOptions={}] Extra Buefy snackbar options to override defaults
         */
        onError(err, message, critical = false, extraOptions = {}) {
            console.error('Error:', err, message); // eslint-disable-line no-console
            const snackbarOptions = {
                message,
                type: 'is-danger',
            };
            // for critical errors, refresh the page after dismissing snackbar
            if (critical) {
                snackbarOptions.indefinite = true;
                snackbarOptions.onAction = () => { this.$router.go(); };
                snackbarOptions.actionText = 'Retry';
            }
            this.$buefy.snackbar.open({
                ...snackbarOptions,
                ...extraOptions,
            });
        },
        onAgree() {
            this.$buefy.snackbar.open();
        },

        /**
         * Shows the "Terms of Use" confirmation dialogue for users who haven't agreed yet.
         * If a user disagrees, redirect to the CANUE homepage.
         */
        showUserAgreement(force = false) {
            const userAgreed = sessionStorage.getItem('touAgreed') === 'true';

            if (userAgreed === false || force) {
                // Component
                this.$buefy.modal.open({
                    parent: this,
                    component: UserAgreementVue,
                    hasModalCard: true,
                    canCancel: ['button'],
                    trapFocus: true,
                });
            }
        },
    },
};
</script>

<style lang="scss">
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

html {
  height: -webkit-fill-available;
}

body {
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.modal-background {
    background-color: rgba(10, 10, 10, 0.3);
}

.modal-card {
    background-color: white;
    width: 50%;
    padding: 1.5rem;
    margin: auto !important;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);

    .modal-card-header {
        display: inline;

        .modal-card-image-container {
            display: flex;
            justify-content: center;
            padding-bottom: 0 1rem;
        }
        .modal-card-image {
            width: 35%;
        }
        .modal-card-title {
            text-align: center;
            font-size: 1.65rem;
            padding: 1rem 0;
            font-weight: 600;
        }
    }

    .modal-card-content {
        padding: 0 1rem;
    }

    .modal-card-buttons {
        display: flex;
        justify-content: center;

        .modal-card-button {
            background-color: $primary-dark;
        }
    }

    .modal-card-language {
        display: flex;
        justify-content: center;
    }

    // mobile-specific style changes
    @include mobile {
        width: 90%;
        padding: 1rem 0;
        .modal-card-header {
            .modal-card-title {
                padding-left: 1rem;
                font-size: 1.1rem;
                text-align: left;
            }
        }

        .modal-card-language {
            padding-bottom: 1rem;
        }
    }
}

// opaque white loading overlay
// to cover up translation placeholders, etc. while the page content loads
.loading-route {
    .loading-background {
        background: #fff;
    }
}

// fade transition for changing routes
.fade-enter-active, .fade-leave-active {
    transition: opacity 400ms;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

// fade-fast transition for toggling components
.fade-fast-enter-active, .fade-fast-leave-active {
    transition: opacity 250ms;
}
.fade-fast-enter, .fade-fast-leave-to {
    opacity: 0;
}

// slide transition for collapsing report header
.slide-enter-active,
.slide-leave-active {
    transition: all 250ms;
    max-height: 300px;
    overflow: hidden;
}
.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0px;
}
</style>
