// Vue I18n configuration & helpers

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import metricsCollection from '@/scoreMetrics';

// all available locales
export const availableLocales = {
    en: {
        cmsLocale: 'en-ca',
    },
    fr: {
        cmsLocale: 'fr-ca',
    },
};

/**
 * Returns true if `locale` is a valid locale for content in the CMS.
 *
 * @param {string} locale - i18n locale/language code
 * @returns {boolean} - if `locale` is supported by this site
 */
export function validLocale(locale) {
    return Object.keys(availableLocales).includes(locale);
}

// default locale: english
export const DEFAULT_LOCALE = Object.keys(availableLocales)[0];

// install i18n plugin
Vue.use(VueI18n);

export const i18n = new VueI18n({
    // default locale: english
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    // suppress fallback warning: CMS fields will be identical across locales so there will be no fallback
    // and the site will be hidden behind a loading screen until the content is loaded
    silentTranslationWarn: true,

    // locale messages will load from the CMS
    messages: {
        en: {
            languageTooltip: 'Language: English',
        },
        fr: {
            languageTooltip: 'Langue : Français',
        },
    },
});

// locales with messages already loaded
const loadedLocales = [];

/**
 * Sets the site locale, and updates the document's HTML "lang" attribute
 *
 * @param {string} lang - i18n locale ("en" or "fr")
 */
export function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
}

/**
 * Replaces line breaks in a string with "<br />".
 *
 * @param {string} input - multiline string
 * @returns {string} - string with HTML line break tags
 */
function nl2br(input) {
    return input.replaceAll(/\n|\r\n|\r/g, '<br />');
}

/**
 * Merges new "metrics" localizations with the existing i18n "messages".
 *
 * @param {object} localizedContent - response object from the CMS API
 * @param {string} locale - i18n locale ("en" or "fr")
 */
function mergeMetricsTranslations(localizedContent, locale) {
    // format score metrics for localization
    const metrics = localizedContent.reduce((acc, curr) => {
        const { data } = curr;

        // use map layer ID as the "fieldName"
        const id = data.map_layer_id;

        // convert subLayers array to an object
        const subLayersArray = data.map_sublayers.map(subLayer => [
            subLayer.layer_id,
            subLayer.layer_title,
        ]);
        const subLayers = Object.fromEntries(subLayersArray);

        // add metric data to an object to merge into vue-i18n's "messages"
        return Object.assign(acc, {
            [id]: {
                title: data.title,
                description: this.$prismic.asHtml(data.description),
                link: data.link,
                subLayers,
            },
        });
    }, {});

    // merge "messages" into vue-i18n
    i18n.mergeLocaleMessage(locale, { metrics });
}

/**
 * Merges new content localizations with the existing i18n "messages".
 *
 * @param {object} localizedContent - response object from the CMS API
 * @param {string} locale - i18n locale ("en" or "fr")
 */
function mergeTranslations(localizedContent, locale) {
    // merge homepage content
    const homepageContent = localizedContent.find(i => i.type === 'homepage_content');
    if (!homepageContent) throw new Error(`Homepage content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        home: {
            header: homepageContent.data.header,
            buttons: {
                map: homepageContent.data.map_button,
                or: homepageContent.data.or,
                tutorial: homepageContent.data.tutorial_button,
                geolocation: homepageContent.data.geolocation_button,
            },
            metricsHeader: homepageContent.data.metrics_section_header,
            metricsSubheader: homepageContent.data.metrics_section_subheader,
        },
    });

    // merge footer content
    const footerContent = localizedContent.find(i => i.type === 'footer_content');
    if (!footerContent) throw new Error(`Footer content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        footer: {
            header: footerContent.data.footer_header,
            links: footerContent.data.footer_links,
        },
    });

    // merge report content
    const reportContent = localizedContent.find(i => i.type === 'report_content');
    if (!reportContent) throw new Error(`Report content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        report: {
            header: {
                geolocation: {
                    header: reportContent.data.geolocation_header,
                    subheader: reportContent.data.geolocation_subheader,
                },
                selectedLocation: {
                    header: reportContent.data.selected_location_header,
                    subheader: reportContent.data.selected_location_subheader,
                },
                sharedLocation: {
                    header: reportContent.data.shared_location_header,
                    subheader: reportContent.data.shared_location_subheader,
                },
            },
            intro: reportContent.data.intro_text,
            share: {
                shareYourScore: this.$prismic.asHtml(reportContent.data.share_your_score),
                copyLink: reportContent.data.copy_button,
            },
            subscribe: {
                emailSubscribe: this.$prismic.asHtml(reportContent.data.subscribe_to_our_newsletter),
                subscribe: reportContent.data.subscribe_button,
            },
            metricsHeader: reportContent.data.metrics_section_header,
            whatsNext: {
                header: reportContent.data.what_you_can_do_header,
                subheader: reportContent.data.what_you_can_do_subheader,
                healthConnections: {
                    button: reportContent.data.health_connections_button,
                    link: reportContent.data.health_connections_link,
                },
                climateChange: {
                    button: reportContent.data.climate_change_button,
                    link: reportContent.data.climate_change_link,
                },
                scoreDetails: {
                    button: reportContent.data.score_details_button,
                    link: reportContent.data.score_details_link,
                },
                communityPlanning: {
                    button: reportContent.data.community_planning_button,
                    prompt: reportContent.data.community_planning_prompt,
                    placeholder: reportContent.data.community_planning_placeholder,
                    searchQuery: reportContent.data.community_planning_search_query_suffix,
                    ok: reportContent.data.community_planning_ok,
                    cancel: reportContent.data.community_planing_cancel,
                },
            },
        },
    });

    // merge site messages (error notices, etc.)
    const messagesContent = localizedContent.find(i => i.type === 'site_messages');
    if (!messagesContent) throw new Error(`Messages content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        errors: {
            myLocationTrySearch: nl2br(messagesContent.data.my_location_error_try_search),
            myLocationTryClick: nl2br(messagesContent.data.my_location_error_try_click),
            searchLocation: nl2br(messagesContent.data.search_location_error),
            tableError: nl2br(messagesContent.data.table_error),
            apiScore: nl2br(messagesContent.data.api_score_error),
            apiCritical: nl2br(messagesContent.data.api_critical_error),
            ema2ilSubscribe: nl2br(messagesContent.data.api_email_subscribe_error),
        },
        notifications: {
            emailSubscribeSuccess: nl2br(messagesContent.data.api_email_subscribe_success),
            linkCopied: nl2br(messagesContent.data.link_copy_success),
        },
        header: {
            siteTitle: messagesContent.data.header_text,
        },
        home: {
            buttons: {
                geolocationTooltip: messagesContent.data.geocoder_tooltip,
            },
            scrollDown: messagesContent.data.scroll_down,
        },
        search: {
            inputPlaceholder: messagesContent.data.search_placeholder,
        },
        report: {
            expand: messagesContent.data.expand,
            collapse: messagesContent.data.collapse,
            subscribe: {
                emailInputHeader: messagesContent.data.email_subscribe_header,
                emailInputMessage: messagesContent.data.email_subscribe_message,
                emailInputPlaceholder: messagesContent.data.email_subscribe_placeholder,
            },
        },
        suggestedPois: {
            header: messagesContent.data.suggested_pois_header,
            intro: messagesContent.data.suggested_pois_intro,
            pois: messagesContent.data.point_of_interest,
        },
        userAgreement: {
            logo: messagesContent.data.user_agreement_logo,
            header: messagesContent.data.user_agreement_header,
            intro: this.$prismic.asText(messagesContent.data.user_agreement_intro),
            body: this.$prismic.asText(messagesContent.data.user_agreement_body),
            blurb: this.$prismic.asText(messagesContent.data.user_agreement_body_blurb),
            agree: messagesContent.data.user_agreement_agree,
            disagree: messagesContent.data.user_agreement_disagree,
        },
        metrics: {
            learnMore: messagesContent.data.metrics_learn_more,
            // each metric will be merged from mergeMetricsTranslations
        },
        toggle: {
            label: messagesContent.data.mobile_metrics_toggle,
        },
        layerControl: {
            header: {
                expanded: messagesContent.data.layer_control_header_expanded,
                collapsed: messagesContent.data.layer_control_header_collapsed,
            },
        },
        mapLegend: {
            highScore: messagesContent.data.legend_high_score,
            lowScore: messagesContent.data.legend_low_score,
            muchWorseScore: messagesContent.data.legend_score_1,
            worseScore: messagesContent.data.legend_score_2,
            averageScore: messagesContent.data.legend_score_3,
            betterScore: messagesContent.data.legend_score_4,
            muchBetterScore: messagesContent.data.legend_score_5,
            tooltipText: messagesContent.data.score_tooltip,
            selectLocationText: messagesContent.data.select_location_text,
            compareFirst: messagesContent.data.compare_first,
            compareLast: messagesContent.data.compare_last,
            compareAverage: messagesContent.data.compare_average,
            canadaText: messagesContent.data.canada,
        },
    });

    // merge navigation content
    const navigationContent = localizedContent.find(i => i.type === 'navigation');
    if (!navigationContent) throw new Error(`Navigation content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        navigation: navigationContent.data.navigation,
    });

    const analyticsCode = localizedContent.find(i => i.type === 'analytics_code');
    if (!analyticsCode) throw new Error(`Analytics code not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        analyticsCode: analyticsCode.data.analytics_code,
    });


    // merge survey message content
    const surveyContent = localizedContent.find(i => i.type === 'user_survey');
    if (!surveyContent) throw new Error(`Survey content not found for lang:${locale}`);

    i18n.mergeLocaleMessage(locale, {
        survey: {
            buttonText: surveyContent.data.button_text,
            popupText: surveyContent.data.popup_text,
            popupTitle: surveyContent.data.popup_title,
            mobileTitle: surveyContent.data.mobile_title,
            dialogPrompt: surveyContent.data.survey_dialog_prompt,
            enabled: String(surveyContent.data.survey_enabled),
            iframeUrl: surveyContent.data.survey_url,
            thankYouMessage: surveyContent.data.thank_you_message,
        },
    });

    // merge survey message content
    const tourInfo = localizedContent.find(i => i.type === 'tour');
    if (!tourInfo) throw new Error(`Tour content not found for lang:${locale}`);

    const tourSteps = [];
    const mobileSteps = [];
    tourInfo.data.tour_step.forEach((step) => {
        let header = false;
        if (step.header.length) {
            header = { title: step.header[0].text };
        }
        let pause = 0;
        if (step.on_before) {
            // set an pause because we probably have to wait for animations to happen.
            pause = 250; // in milliseconds
        }
        tourSteps.push({
            target: (step.target_id === 'fullscreen') ? false : step.target_id,
            content: this.$prismic.asHtml(step.step_content),
            header,
            params: {
                placement: step.placement,
            },
            onBefore: step.on_before,
            onAfter: step.on_after,
            pause,
        });
    });
    tourInfo.data.mobile_step.forEach((step) => {
        let header = false;
        if (step.header.length) {
            header = { title: step.header[0].text };
        }
        let pause = 0;
        if (step.on_before) {
            // set an pause because we probably have to wait for animations to happen.
            pause = 250; // in milliseconds
        }
        mobileSteps.push({
            target: (step.target_id === 'fullscreen') ? false : step.target_id,
            content: this.$prismic.asHtml(step.step_content),
            header,
            params: {
                placement: step.placement,
            },
            onBefore: step.on_before,
            onAfter: step.on_after,
            pause,
        });
    });

    // this _super clever and helpful_ function will make arrays into objects! Wow! Super helpful and clever! 🐎💩
    i18n.mergeLocaleMessage(locale, {
        tourHeader: tourInfo.data.tour_header[0].text,
        tourSteps, // we'll have to run Object.values on this later to _reconvert_ it back to an array as expected
        mobileHeader: tourInfo.data.mobile_header[0].text,
        mobileSteps, // we'll have to run Object.values on this later to _reconvert_ it back to an array as expected
    });

    loadedLocales.push(locale);
}

/**
 * Queries the CMS API for site content for the given locale.
 *
 * @param {string} locale - i18n locale ("en" or "fr")
 * @returns {Promise} - promise resolved when all query results are loaded
 */
export async function loadLanguageAsync(locale) {
    // skip locales that are already loaded
    if (loadedLocales.includes(locale)) return;

    // If the language hasn't been loaded yet,
    // query CMS for localized content
    const { cmsLocale } = availableLocales[locale];

    // query for score metrics content
    const metricsQuery = this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'score_metric'),
        {
            lang: cmsLocale,

            //  order alphabetically
            orderings: '[my.score_metric.title]',
        },
    ).then(({ results: metrics }) => {
        // populate score metrics array to be used throughout the site
        metricsCollection.setMetrics(metrics);

        // merge localized content into existing i18n messages
        mergeMetricsTranslations.call(this, metrics, locale);
        return metrics;
    });

    // query for page content
    const contentQuery = this.$prismic.client.query(
        this.$prismic.Predicates.any('document.type', [
            'homepage_content',
            'footer_content',
            'report_content',
            'site_messages',
            'user_survey',
            'tour',
            'navigation',
            'analytics_code',
        ]),
        { lang: cmsLocale },
    ).then(({ results: localizedContent }) => {
        // merge localized content into existing i18n messages
        mergeTranslations.call(this, localizedContent, locale);
        return localizedContent;
    });

    // wait for all queries to finish
    await Promise.all([
        metricsQuery,
        contentQuery,
    ]);
}
