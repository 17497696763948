<template>
    <transition
        name="fade"
        mode="out-in"
    >
        <div class="modal-card">
            <div class="modal-card-header">
                <div class="modal-card-image-container">
                    <img
                        class="modal-card-image"
                        :src="this.$t('userAgreement.logo.url')"
                    >
                </div>
                <h1 class="modal-card-title">
                    {{ this.$t('userAgreement.header') }}
                </h1>
            </div>
            <div class="modal-card-content">
                <p><strong>{{ this.$t('userAgreement.intro') }}</strong></p>
                <br>
                <p>{{ this.$t('userAgreement.body') }}</p>
                <!-- <br />
                <p><em>{{ this.$t('userAgreement.blurb') }}</em></p> -->
            </div>
            <br>
            <div class="modal-card-buttons">
                <b-button
                    class="modal-card-button"
                    type="is-primary"
                    @click="agree(); $emit('close')"
                >
                    {{ this.$t('userAgreement.agree') }}
                </b-button>
            </div>
            <br>
            <div class="modal-card-language">
                <a
                    href="en"
                    native-value="en"
                >
                    English
                </a>
                <p>&nbsp;/&nbsp;</p>
                <a
                    href="fr"
                    native-value="fr"
                >
                    Fran&ccedil;ais
                </a>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'UserAgreement',

    methods: {
        agree: () => {
            // remember agreement for this session
            sessionStorage.setItem('touAgreed', 'true');
        },
    },
};
</script>
