// mapbox-gl stuff
const DEFAULT_MAPBOX_TOKEN = 'pk.eyJ1IjoiY2FudWUiLCJhIjoiY2p3d2hyeDhrMDR1czRibnV6ZXV3eXU4biJ9.t6VjISlgs5BTbWbhVWdb4w';
export const MAPBOX_ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN || DEFAULT_MAPBOX_TOKEN;
export const MAPBOX_DEFAULT_STYLE = 'mapbox://styles/canue/clcus5kax000q14owjbzv445f';

// api url
export const API_URL = process.env.VUE_APP_API_URL || '/api';

// hardcoded critical error in case connection to the CMS fails
export const API_CRITICAL_ERROR = `There was a problem loading this page.<br>
Please try again in a moment.`;
