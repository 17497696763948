<template>
    <b-button
        :loading="loading"
        class="my-location-button"
        icon-left="crosshairs-gps"
        @click="onClick"
    >
        <slot />
    </b-button>
</template>

<script>
import { LngLat } from 'mapbox-gl';

export default {
    data: () => ({
        loading: false,
    }),

    methods: {
        /**
         * use geolocation api to look up the user's location
         */
        onClick() {
            this.loading = true;
            navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
        },

        /**
         * on successful user geolocation, emit an event with the coordinates
         * emits "success" with LngLat object
         * @param  {GeolocationPosition} position position parameter from successful geolocation
         */

        onSuccess(position) {
            const { longitude, latitude } = position.coords;
            const lngLat = new LngLat(longitude, latitude);
            this.$emit('success', lngLat);
            this.loading = false;

            // track analytics event
            if (this.$slots.default) {
                window.gtag('event', 'geolocation-search', { action: 'Button', label: 'Find my device location' });
            } else {
                window.gtag('event', 'geolocation-search', { action: 'Button', label: 'Geolocation icon' });
            }
        },

        /**
         * on user geolocation error, emit "error"
         * @param  {PositionError} positionError error object to be emitted
         */
        onError(positionError) {
            this.$emit('error', positionError);
            this.loading = false;

            // track analytics event
            window.gtag('event', 'blocked-geolocation-search', { action: 'Button' });
        },
    },
};
</script>

<style lang="scss">

</style>
