<template>
    <div class="hp-section partners">
        <div class="partners-header">
            <h4>
                {{ partners.header }}
            </h4>
        </div>
        <a
            v-for="(partner, idx) in partners.links"
            :key="idx"
            class="hp-partner"
            :href="partner.link_href"
            target="_blank"
        >
            <img
                :src="partner.link_img.url"
                :alt="partner.link_alt"
            >
        </a>
    </div>
</template>
<script>

export default {
    name: 'PartnersSection',

    props: {
        partners: {
            type: Object,
            required: true,
        },
    },
};

</script>

<style lang="scss">
.partners {
    display: grid;
    grid-template-columns: 1fr repeat(4, 1fr);
    @include mobile {
        grid-template-columns: repeat(4, 1fr);
    }
    column-gap: 0.8em;
    align-items: center;
    margin-top: 1em;
    padding: 1rem;

    h4 {
        font-weight: bold;
        line-height: 1.2em;
        font-size: 0.9em;
    }
}
@include mobile {
    .partners-header {
        grid-column-start: 1;
        grid-column-end: 5;
        padding-bottom: 0.5em;
    }
}
</style>
