<template>
    <div class="user-survey-wrap box m-0">
        <p
            class="mb-1"
        >
            {{ $t('survey.dialogPrompt') }}
            <button
                type="button"
                class="delete is-pulled-right"
                @click="$emit('close')"
            />
        </p>

        <transition
            name="fade"
            mode="out-in"
        >
            <iframe
                v-if="!completed"
                id="JotFormIFrame-211887094057261"
                class="user-survey"
                title="User Survey"
                allowtransparency="true"
                allowfullscreen="true"
                allow="geolocation; microphone; camera"
                :src="$t('survey.iframeUrl')"
                frameborder="0"
            />
            <div
                v-else
                class="user-survey completed is-flex"
            >
                <span
                    class="is-size-4"
                    v-text="$t('survey.thankYouMessage')"
                />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'UserSurvey',

    data: () => ({
        completed: false,
    }),

    // bind iframe postmessage handler
    mounted() {
        window.addEventListener('message', this.handleMessage);
    },

    // unbind iframe postmessage handler
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage);
    },

    methods: {
        /**
         * Handles message events from the iframe.
         * Sets "completed" variable when the survey is submitted.
         *
         * @param {Event} ev - window "message" event
         */
        handleMessage(ev) {
            const isJotForm = ev.origin.includes('jotform');
            if (!isJotForm) return;

            // when the survey is submitted
            if (ev.data.action === 'submission-completed') {
                // after 250ms: show thankyou message
                setTimeout(() => { this.completed = true; }, 250);

                // after 3 seconds: auto-close the modal
                setTimeout(() => { this.$parent.close(); }, 3000);

                // track analytics event
                window.gtag('event', 'survey-link-submit', { action: 'Link' });
            }
        },
    },
};
</script>

<style lang="scss">
.user-survey {
    // styles from jotform iframe
    min-width: 100%;
    height: calc(100vh - 20rem);
    border: none;

    @include desktop {
        height: calc(100vh - 10rem);
        max-height: 1000px;
    }

    &.completed {
        justify-content: center;
        align-items: center;
    }
}
</style>
