<template>
    <div
        :id="'v-step-' + hash"
        :ref="'v-step-' + hash"
        :class="{ 'v-step--sticky': isSticky }"
        class="v-step"
    >
        <slot name="header">
            <div
                v-if="formattedTitle"
                class="hp-title v-step__header"
            >
                {{ formattedTitle }}
                <b-icon
                    icon="close"
                    size="is-small"
                    class="close-icon is-clickable is-pulled-right"
                    @click.native="finish"
                />
            </div>
        </slot>

        <slot name="content">
            <div class="v-step__content">
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="step.header.title"
                    class="hp-title"
                    v-html="step.header.title"
                />
                <div
                    v-if="step.content"
                    v-html="step.content"
                />
                <!-- eslint-enable vue/no-v-html -->
                <div v-else>
                    This is a demo step! The id of this step is {{ hash }} and it targets {{ step.target }}.
                </div>
            </div>
        </slot>

        <slot name="actions">
            <div class="v-step__buttons">
                <b-button
                    v-if="!isLast && isButtonEnabled('buttonSkip')"
                    class="v-step__button v-step__button-skip"
                    type="is-primary"
                    :label="labels.buttonSkip"
                    @click.prevent="skip"
                />
                <b-button
                    v-if="!isFirst && isButtonEnabled('buttonPrevious')"
                    class="v-step__button v-step__button-previous"
                    type="is-text"
                    icon-left="chevron-left"
                    :label="labels.buttonPrevious"
                    @click.prevent="previousStep"
                />
                <b-button
                    v-if="!isLast && isButtonEnabled('buttonNext')"
                    class="v-step__button v-step__button-next"
                    type="is-primary"
                    :label="labels.buttonNext"
                    @click.prevent="nextStep"
                />
                <b-button
                    v-if="isLast && isButtonEnabled('buttonStop')"
                    class="v-step__button v-step__button-stop"
                    type="is-primary"
                    :label="labels.buttonStop"
                    @click.prevent="finish"
                />
            </div>
        </slot>

        <div
            class="v-step__arrow"
            :class="{ 'v-step__arrow--dark': step.header && step.header.title }"
            data-popper-arrow
        />
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import jump from 'jump.js';
import sum from 'hash-sum';
import { DEFAULT_STEP_OPTIONS, HIGHLIGHT } from '@/vue-tour';
import maxSize from 'popper-max-size-modifier';

// fix for when a tour step is too large for the screen
const applyMaxSize = {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn({ state }) {
        const { height } = state.modifiersData.maxSize;
        // eslint-disable-next-line no-param-reassign
        state.styles.popper = {
            ...state.styles.popper,
            maxHeight: `${height}px`,
        };
    },
};


export default {
    name: 'VStep',
    props: {
        step: {
            type: Object,
            required: true,
        },
        currentStep: {
            type: Number,
            required: true,
        },
        totalSteps: {
            type: Number,
            required: true,
        },
        previousStep: {
            type: Function,
            required: false,
            default: () => {},
        },
        nextStep: {
            type: Function,
            required: false,
            default() {
                this.next();
            },
        },
        stop: {
            type: Function,
            required: false,
            default() {
                this.stop();
            },
        },
        skip: {
            type: Function,
            default() {
                this.stop();
            },
        },
        finish: {
            type: Function,
            default() {
                this.stop();
            },
        },
        isFirst: {
            type: Boolean,
        },
        isLast: {
            type: Boolean,
        },
        labels: {
            type: Object,
            required: false,
            default() { return {}; },
        },
        enabledButtons: {
            type: Object,
            required: false,
            default() { return {}; },
        },
        highlight: {
            type: Boolean,
        },
        stopOnFail: {
            type: Boolean,
        },
        debug: {
            type: Boolean,
        },
    },
    data() {
        return {
            hash: sum(this.step.target),
            targetElement: document.querySelector(this.step.target),
        };
    },
    computed: {
        params() {
            return {
                ...DEFAULT_STEP_OPTIONS,
                ...{ highlight: this.highlight }, // Use global tour highlight setting first
                ...{ enabledButtons: { ...this.enabledButtons } },
                modifiers: [...DEFAULT_STEP_OPTIONS.modifiers, maxSize, applyMaxSize],
                ...this.step.params, // Then use local step parameters if defined
            };
        },
        /**
         * A step is considered sticky if it has no target
         * set the target to the boolean value "false" to make it fullscreen, essentially.
         */
        isSticky() {
            return !this.step.target;
        },
        formattedTitle() {
            let title = this.labels.titleTemplate;
            if (!title) {
                return false;
            }
            const titleVars = [
                {
                    key: 'currentStep',
                    value: this.currentStep + 1, // currentStep is 0-indexed
                },
                {
                    key: 'totalSteps',
                    value: this.totalSteps,
                },
            ];
            titleVars.forEach((tvar) => {
                title = title.replace(`:${tvar.key}:`, tvar.value);
            });
            return title;
        },
    },
    mounted() {
        this.$emit('onbefore');
        this.createStep();
    },
    destroyed() {
        this.removeHighlight();
    },
    methods: {
        createStep() {
            if (this.debug) {
                // eslint-disable-next-line no-console
                console.log(`[Vue Tour] The target element ${this.step.target} of .v-step[id="${this.hash}"] is:`, this.targetElement);
            }

            if (this.isSticky) {
                // set a timeout and then appendChild
                setTimeout(() => {
                    document.body.appendChild(this.$refs[`v-step-${this.hash}`]);
                }, this.step.pause);
            } else if (this.targetElement) {
                setTimeout(() => {
                    this.enableScrolling();
                    this.createHighlight();
                    document.body.appendChild(this.$refs[`v-step-${this.hash}`]);
                    createPopper(
                        this.targetElement,
                        this.$refs[`v-step-${this.hash}`],
                        this.params,
                    );
                }, this.step.pause);
            } else {
                if (this.debug) {
                    // eslint-disable-next-line no-console
                    console.error(`[Vue Tour] The target element ${this.step.target} of .v-step[id="${this.hash}"] does not exist!`);
                }
                this.$emit('targetNotFound', this.step);
                if (this.stopOnFail) {
                    this.stop();
                } else {
                    this.nextStep();
                }
            }
        },
        enableScrolling() {
            if (this.params.enableScrolling) {
                if (this.step.duration || this.step.offset) {
                    const jumpOptions = {
                        duration: this.step.duration || 1000,
                        offset: this.step.offset || 0,
                        callback: undefined,
                        a11y: false,
                    };

                    jump(this.targetElement, jumpOptions);
                } else {
                    // Use the native scroll by default if no scroll options has been defined
                    this.targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
        isHighlightEnabled() {
            if (this.debug) {
                // eslint-disable-next-line no-console
                console.log(`[Vue Tour] Highlight is ${this.params.highlight ? 'enabled' : 'disabled'} for .v-step[id="${this.hash}"]`);
            }
            return this.params.highlight;
        },
        createHighlight() {
            if (this.isHighlightEnabled()) {
                document.body.classList.add(HIGHLIGHT.classes.active);
                const transitionValue = window.getComputedStyle(this.targetElement).getPropertyValue('transition');

                // Make sure our background doesn't flick on transitions
                if (transitionValue !== 'all 0s ease 0s') {
                    this.targetElement.style.transition = `${transitionValue}, ${HIGHLIGHT.transition}`;
                }

                this.targetElement.classList.add(HIGHLIGHT.classes.targetHighlighted);
                // The element must have a position, if it doesn't have one, add a relative position class
                if (!window.getComputedStyle(this.targetElement).getPropertyValue('position')) {
                    this.targetElement.classList.add(HIGHLIGHT.classes.targetRelative);
                }
            } else {
                document.body.classList.remove(HIGHLIGHT.classes.active);
            }
        },
        removeHighlight() {
            if (this.isHighlightEnabled() && !this.isSticky) {
                const target = this.targetElement;
                if (this.targetElement) {
                    const currentTransition = this.targetElement.style.transition;
                    this.targetElement.classList.remove(HIGHLIGHT.classes.targetHighlighted);
                    this.targetElement.classList.remove(HIGHLIGHT.classes.targetRelative);
                    // Remove our transition when step is finished.
                    if (currentTransition.includes(HIGHLIGHT.transition)) {
                        setTimeout(() => {
                            target.style.transition = currentTransition.replace(`, ${HIGHLIGHT.transition}`, '');
                        }, 0);
                    }
                }
            }
        },
        isButtonEnabled(name) {
            return Object.prototype.hasOwnProperty.call(this.params.enabledButtons, name)
                ? this.params.enabledButtons[name] : true;
        },
    },
};
</script>

<style lang="scss">
$border-radius: 4px;

.v-step {
    max-width: 320px;
    border-radius: $border-radius;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    pointer-events: auto;
    z-index: 10000;

    & > * {
        padding: 0.5rem 1rem;
    }

    &--sticky {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50vw;
        max-width: 50vw;
        box-shadow: 0 0 0 1em rgba(0,0,0,0), 0 0 0 99999px rgba(0,0,0,.4);
        pointer-events: auto;
        border-radius: $border-radius;
        z-index: 9999;
        @include mobile {
            left: 50vw;
            max-width: 80vw;
            width: 80vw;
            transform: translate(-50%, -50%);
        }

        & .v-step__arrow {
            display: none;
        }
    }
}

.v-step__arrow,
.v-step__arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit;
}

.v-step__arrow {
    visibility: hidden;
}

.v-step__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.v-step[data-popper-placement^="top"] > .v-step__arrow {
    bottom: -3px;
}

.v-step[data-popper-placement^="bottom"] > .v-step__arrow {
    top: -13px;
    @include mobile {
        top: -13px;
    }
}

.v-step[data-popper-placement^="right"] > .v-step__arrow {
    left: calc(-1rem - 5px);
    @include mobile {
        left:  calc(-1rem + 3px);
    }
}

.v-step[data-popper-placement^="left"] > .v-step__arrow {
    right: calc(-1rem + 5px);
    @include mobile {
        right:  calc(-1rem + 3px);
    }
}

  /* Custom */

.v-step__header {
    font-size: 0.8em;
    div {
        display: inline-block;
    }
    .close-icon {
        @include mobile {
            font-size: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.v-step__content {
    margin: 0 0 4rem 0;

    .hp-title {
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 1rem;
    }
    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

.v-step__buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.v-step__button {
    display: inline-block;
    font-size: .8rem;
    line-height: 1rem;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: bold;
    padding: 0.5em 2em;
}
.v-step__button-next {
    float:right;
}
.v-step__button-previous {
    float:left;
    padding: 0.5em 1em;
}
.v-step__button-stop {
    float:right;
}
</style>
