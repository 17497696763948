<template>
    <div class="locale-switcher">
        <b-tooltip
            :label="$t('languageTooltip')"
            position="is-left"
        >
            <b-field>
                <b-radio-button
                    v-model="$i18n.locale"
                    size="is-small"
                    native-value="en"
                    type="is-primary is-light"
                >
                    <b-icon
                        v-if="$i18n.locale === 'en'"
                        size="is-small"
                        icon="web"
                    />
                    <span>En</span>
                </b-radio-button>

                <b-radio-button
                    v-model="$i18n.locale"
                    size="is-small"
                    native-value="fr"
                    type="is-primary is-light"
                >
                    <b-icon
                        v-if="$i18n.locale === 'fr'"
                        size="is-small"
                        icon="web"
                    />
                    <span>Fr</span>
                </b-radio-button>
            </b-field>
        </b-tooltip>
    </div>
</template>

<script>
export default {
    name: 'LocaleSwitcher',
};
</script>
