<template>
    <div class="burger-menu">
        <div @click="$emit('close')">
            <b-icon
                icon="close"
                class="is-pulled-right"
                pack="mdi"
            />
        </div>
        <div class="logo-container">
            <img
                :src="$t('userAgreement.logo.url')"
                class="logo"
            >
        </div>
        <hr>
        <div
            v-if="showCommunity || showSubscribe"
            class="shademan"
            @click="showCommunity=false; showSubscribe=false"
        >
            <div
                class="mobile-popup-window"
                @click.stop
            >
                <community-plan-search-section
                    v-if="showCommunity"
                    class="mobile-popup"
                    @close="showCommunity=false"
                />
                <email-subscribe
                    v-if="showSubscribe"
                    class="mobile-popup"
                    @close="showSubscribe=false"
                />
            </div>
        </div>
        <div class="menu-links">
            <div class="mb-2">
                <img
                    class="mobile-item"
                    :src="icons.peopleIcon"
                >
                <a
                    class="mobile-item"
                    @click="showCommunity=true; showSubscribe=false"
                >
                    Community Planning
                </a>
            </div>
            <div
                v-for="nav in this.$t('navigation')"
                :key="nav.title"
                class="mb-2"
            >
                <img
                    class="mobile-item"
                    :src="icons[nav.icon]"
                >
                <a
                    :href="`${nav.url ? nav.url.url : ''}`"
                    target="_blank"
                    class="mobile-item"
                >
                    {{ nav.title }}
                </a>
            </div>
            <div>
                <img
                    class="mobile-item"
                    :src="icons.infoIcon"
                >
                <a
                    href="?tut=true"
                    class="mobile-item"
                >
                    {{ $t('home.buttons.tutorial') }}
                </a>
            </div>
        </div>
        <div id="spacer" />
        <b-button
            type="is-primary"
            @click="showSubscribe=true; showCommunity=false"
        >
            {{ $t('report.subscribe.emailInputHeader') }}
        </b-button>
        <partners-section
            class="partners-section"
            :partners="this.$t('footer')"
        />
    </div>
</template>

<script>
import EmailSubscribe from '@/components/EmailSubscribe.vue';
import PartnersSection from '@/components/PartnersSection.vue';
import CommunityPlanSearchSection from '@/components/CommunityPlanSearchSection.vue';
import planSvg from '@/assets/fluent_people-community-16-filled.svg';
import researchSvg from '@/assets/fluent_brain-circuit-24-filled.svg';
import methodSvg from '@/assets/emojione-monotone_input-numbers.svg';
import learnSvg from '@/assets/ri_plant-fill.svg';
import infoSvg from '@/assets/info.svg';

export default {
    name: 'BURGER',

    components: {
        CommunityPlanSearchSection,
        EmailSubscribe,
        PartnersSection,
    },

    data: () => ({
        showCommunity: false,
        showSubscribe: false,
        icons: {
            peopleIcon: planSvg,
            methodIcon: methodSvg,
            researchIcon: researchSvg,
            learnIcon: learnSvg,
            infoIcon: infoSvg,
        },
    }),
};
</script>

<style lang="scss">
.burger-menu {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    height: 100vh;
    width: 80vw;
}

.logo-container {
    display: flex;
    justify-content: center;
    position: relative;

    .logo {
        width: 50%;
    }
}

.menu-links {
    display: flex;
    flex-direction: column;
    padding-left: 0.5em;

    a {
        padding-left: 0.5rem;
    }
    .mobile-item {
        display: inline-block;
        vertical-align: middle;
    }
}

.shademan {
    top: 0;
    left: 0;

    .mobile-popup-window {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        z-index: 12;

        .mobile-popup {
            border-radius: 0.3em;
        }
    }
}

#spacer {
    height: 5vh;
}
</style>
