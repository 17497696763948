import { Loader } from '@googlemaps/js-api-loader';
import EventBus from '@/eventBus';

const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ['places'],
});

// export a promise that resolves to an object of google apis
// @SEE: https://googlemaps.github.io/js-api-loader
export default loader.load()
    .catch((error) => {
        // emit critical app error if the api fails to load
        EventBus.$emit('error', error, this.$t('errors.apiCritical'), true);
        return error;
    });
