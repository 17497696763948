<template lang="html">
    <b-autocomplete
        id="hp-geocoder-search"
        class="geocoder-search"
        expanded
        icon="magnify"
        :placeholder="$t('search.inputPlaceholder')"
        keep-first
        clear-on-select
        :data="data"
        open-on-focus
        :loading="isFetching"
        @focus="onInputFocused"
        @typing="getAsyncData"
        @select="selectAddress"
    >
        <template slot-scope="props">
            <div class="geocoder-search-result">
                <b-icon
                    type="is-primary"
                    size="is-small"
                    pack="mdi"
                    :icon="props.option.previousLocation ? 'history' : 'map-marker'"
                />
                <b>{{ props.option.structured_formatting.main_text }}</b>
                <small class="has-text-grey">
                    {{ props.option.structured_formatting.secondary_text }}
                </small>
            </div>
        </template>
    </b-autocomplete>
</template>

<script>
import debounce from 'lodash.debounce';
import { LngLat } from 'mapbox-gl';
import EventBus from '@/eventBus';
import googleApi from '@/googleApi';

export default {
    data: () => ({
        // google maps places autocomplete service
        autocompleteService: null,
        // google maps places service
        placeService: null,
        data: [],
        selected: null,
        isFetching: false,
        mapCenter: null,
    }),

    created() {
        // listen for map move events emitted through the event bus
        EventBus.$on('map:moveend', this.onMapMove);
    },

    mounted() {
        // google API must be in mounted event and created event will not work
        this.initGoogleMapService();
    },

    beforeDestroy() {
        // remove event listeners on the EventBus
        EventBus.$off('map:moveend', this.onMapMove);
    },

    methods: {
        /**
         * initialize and store Google Maps places autocomplete service and places service API
         * for Vue to access Google Maps places services we need to add 'window' in front of reference
         */
        async initGoogleMapService() {
            const google = await googleApi;
            // places autocomplete service
            this.autocompleteService = new google.maps.places.AutocompleteService();
            // places service accepts as argument either a google map object or a node where to render results
            this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
        },

        /**
         * query the google places autocomplete service API and set autocomplete options
         * @param  {String} name search string
         */
        getAsyncData: debounce(async function fetchPlacePredictions(name) {
            if (!name.length) {
                this.data = this.getPreviousLocations();
                return;
            }
            this.isFetching = true;

            try {
                const { predictions } = await this.autocompleteService.getPlacePredictions({
                    input: name,
                    componentRestrictions: { country: 'ca' },
                });
                this.data = predictions;
            } catch (error) {
                this.data = [];
                EventBus.$emit('error', error, this.$t('errors.searchLocation'));
            } finally {
                this.isFetching = false;
            }

            // build array of search results
            const previousLocations = this.getMatchingPreviousLocations(name);
            // filter out any search results that are already in previousLocations
            const newLocations = this.data.filter(result => (
                !previousLocations.some(previousLocation => (
                    previousLocation.place_id === result.place_id
                ))
            ));
            this.data = [...previousLocations, ...newLocations];
        }, 200),

        /**
         * select an option from the list of search results
         * emits a mapboxgl.LngLat object
         * @param {Object} option google maps geocoder result
         */
        async selectAddress(option) {
            // select item
            this.selected = option;

            if (option === null) {
                return;
            }

            // ensure option is included in list of previous locations
            if (!option.previousLocation) {
                this.addToPreviousLocations(option);
            }

            // query the google places service API and get place location details
            const google = await googleApi;
            this.placeService.getDetails({ placeId: this.selected.place_id }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry.location) {
                    const placeName = place.name;
                    const placeAddress = place.formatted_address;
                    const placeTypes = place.types;
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();

                    const lngLat = new LngLat(lng, lat);
                    // go to map route
                    this.$emit(
                        'select',
                        lngLat,
                        {
                            firstLine: placeName,
                            secondLine: placeAddress,
                            types: placeTypes,
                        },
                    );

                    // track analytics event
                    window.gtag('event', 'address-search', { action: 'Search', label: `${placeAddress}` });
                }
            });
        },

        /**
         * handle map move events; store the most recent map center
         * to do proximity bias on geocoder queries
         * @param  {mapboxgl.LngLat} center map center
         */
        onMapMove({ center }) {
            this.mapCenter = center;
        },

        /**
         * When the input is focused and the data object is empty, get the previously searched locations
         */
        onInputFocused() {
            if (!this.data.length) {
                this.data = this.getPreviousLocations();
            }
        },

        /**
         * Store the given locations in session storage as the previously searched locations.
         *
         * @param {Array} previousLocations
         */
        setPreviousLocations(previousLocations) {
            sessionStorage.setItem('previous-locations', JSON.stringify(previousLocations));
        },

        /**
         * Get the previously searched locations from session storage.
         *
         * @returns {Array} - locations that were previously searched for
         */
        getPreviousLocations() {
            return JSON.parse(sessionStorage.getItem('previous-locations')) || [];
        },

        /**
         * Get the previously searched locations that match the search input
         *
         * @param {String} searchInput
         * @returns {Array} - locations that the searchInput can match
         */
        getMatchingPreviousLocations(searchInput) {
            const allPreviousLocations = this.getPreviousLocations();

            const lowerCaseSearchInput = searchInput.toLowerCase();
            return allPreviousLocations.filter((location) => {
                const lowerCaseLocation = `${location.description}`.toLowerCase();
                return lowerCaseLocation.includes(lowerCaseSearchInput);
            });
        },

        /**
         * Add the given item to the previously search locations in session storage.
         *
         * @param {Object} - a location that has been searched for by the user
         */
        addToPreviousLocations(newLocation) {
            const previousLocations = this.getPreviousLocations();
            previousLocations.push({
                ...newLocation,
                previousLocation: true,
            });
            this.setPreviousLocations(previousLocations);
        },
    },
};
</script>

<style lang="scss">
.geocoder-search {
    input {
        height: 2.5rem;
        font-size: 0.75rem;
    }

    // need a super specific selector b/c we want to adjust the padding inside the
    // input in order to give more space between the icon and the input text
    .control.has-icons-left input {
        padding-left: 2.25rem;
    }

    .geocoder-search-result .icon {
        margin-right: 0.15rem;
    }
}

#hp-geocoder-search {
    // nuke it from orbit
    box-shadow: none;
    -webkit-box-shadow: none;
}
</style>
