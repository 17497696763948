<template>
    <div>
        <div class="v-tour">
            <slot
                :current-step="currentStep"
                :steps="validSteps"
                :previous-step="previousStep"
                :next-step="nextStep"
                :stop="stop"
                :skip="skip"
                :finish="finish"
                :is-first="isFirst"
                :is-last="isLast"
                :labels="customOptions.labels"
                :enabled-buttons="customOptions.enabledButtons"
                :highlight="customOptions.highlight"
                :debug="customOptions.debug"
            >
                <!--Default slot {{ currentStep }}-->
                <v-step
                    v-if="validSteps[currentStep]"
                    :key="currentStep"
                    :step="validSteps[currentStep]"
                    :current-step="currentStep"
                    :total-steps="validSteps.length"
                    :previous-step="previousStep"
                    :next-step="nextStep"
                    :stop="stop"
                    :skip="skip"
                    :finish="finish"
                    :is-first="isFirst"
                    :is-last="isLast"
                    :labels="customOptions.labels"
                    :enabled-buttons="customOptions.enabledButtons"
                    :highlight="customOptions.highlight"
                    :stop-on-fail="customOptions.stopOnTargetNotFound"
                    :debug="customOptions.debug"
                    @targetNotFound="$emit('targetNotFound', $event)"
                    @onbefore="$emit('onbefore', {action: validSteps[currentStep].onBefore})"
                />
            </slot>
        </div>
        <svg
            v-if="step && step.target"
            class="overlay"
        >
            <path :d="overlayPath" />
        </svg>
    </div>
</template>

<script>
import { DEFAULT_CALLBACKS, DEFAULT_OPTIONS, KEYS } from '@/vue-tour';
import VStep from '@/components/VStep';

export default {
    name: 'VTour',
    components: {
        VStep,
    },
    props: {
        steps: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
        options: {
            type: Object,
            default: () => DEFAULT_OPTIONS,
        },
        callbacks: {
            type: Object,
            default: () => DEFAULT_CALLBACKS,
        },
        // this is literally just a little flag that will update the "validSteps"
        // data property when it changes. We have a watcher further down that will
        // update the validSteps when the environ or demographic change
        updateSteps: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            currentStep: -1,
            validSteps: { ...this.steps },
            overlayPath: '',
        };
    },
    computed: {
    // Allow us to define custom options and merge them with the default options.
    // Since options is a computed property, it is reactive and can be updated during runtime.
        customOptions() {
            return {
                ...DEFAULT_OPTIONS,
                ...this.options,
            };
        },
        customCallbacks() {
            return {
                ...DEFAULT_CALLBACKS,
                ...this.callbacks,
            };
        },
        // Return true if the tour is active, which means that there's a VStep displayed
        isRunning() {
            return this.currentStep > -1 && this.currentStep < this.numberOfSteps;
        },
        isFirst() {
            return this.currentStep === 0;
        },
        isLast() {
            return this.currentStep === this.validSteps.length - 1;
        },
        numberOfSteps() {
            return this.validSteps.length;
        },
        step() {
            return this.validSteps[this.currentStep];
        },
    },
    watch: {
        // this gets triggered whenever environment or demographic change, even if they get set to
        // something that has the same number of letters
        updateSteps() {
            this.updateValidSteps();
        },
        currentStep() {
            if (this.step) {
                setTimeout(() => {
                    // if the current step has a pause, execute the pause and set the overlayPath
                    const { innerWidth: w, innerHeight: h } = window;
                    const targetElement = document.querySelector(this.step.target);
                    const {
                        offsetWidth: width,
                        offsetHeight: height,
                    } = targetElement;
                    const { x, y } = targetElement.getBoundingClientRect();
                    const r = 5;
                    this.overlayPath = `M${w},${h} H0 V0 H${w} V${h} Z M${x + r},${y} a${r},${r},0,0,0-${r},${r} V${height + y - r} a${r},${r},0,0,0,${r},${r} H${width + x - r} a${r},${r},0,0,0,${r}-${r} V${y + r} a${r},${r},0,0,0-${r}-${r} Z`;
                }, this.step.pause);
            }
        },
    },
    mounted() {
        this.updateValidSteps();
    },
    beforeDestroy() {
    // Remove the keyup listener if it has been defined
        if (this.customOptions.useKeyboardNavigation) {
            window.removeEventListener('keyup', this.handleKeyup);
        }
    },
    methods: {
        updateValidSteps() {
            this.validSteps = [];
            this.steps.forEach((step) => {
                if (
                    step.target === false
                    || !this.customOptions.removeInvalidSteps
                    || document.querySelector(step.target)
                ) {
                    this.validSteps.push(step);
                }
            });
        },
        async start(startStep) {
            // Register keyup listeners for this tour
            if (this.customOptions.useKeyboardNavigation) {
                window.addEventListener('keyup', this.handleKeyup);
            }

            // Wait for the DOM to be loaded, then start the tour
            const sStep = typeof startStep !== 'undefined' ? parseInt(startStep, 10) : 0;
            const step = this.validSteps[sStep];

            const process = () => new Promise((resolve) => {
                setTimeout(() => {
                    this.customCallbacks.onStart();
                    this.currentStep = sStep;
                    resolve();
                }, this.customOptions.startTimeout);
            });

            if (typeof step.before !== 'undefined') {
                try {
                    await step.before('start');
                } catch (e) {
                    return Promise.reject(e);
                }
            }
            await process();

            return Promise.resolve();
        },
        async previousStep() {
            this.$emit('onafter', { action: this.validSteps[this.currentStep].onAfter });
            const futureStep = this.currentStep - 1;

            const process = () => new Promise((resolve) => {
                this.customCallbacks.onPreviousStep(this.currentStep);
                this.currentStep = futureStep;
                resolve();
            });

            if (futureStep > -1) {
                const step = this.validSteps[futureStep];
                if (typeof step.before !== 'undefined') {
                    try {
                        await step.before('previous');
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
                await process();
            }

            return Promise.resolve();
        },
        async nextStep() {
            this.$emit('onafter', { action: this.validSteps[this.currentStep].onAfter });
            const futureStep = this.currentStep + 1;

            const process = () => new Promise((resolve) => {
                this.customCallbacks.onNextStep(this.currentStep);
                this.currentStep = futureStep;
                resolve();
            });

            if (futureStep < this.numberOfSteps && this.currentStep !== -1) {
                const step = this.validSteps[futureStep];
                if (typeof step.before !== 'undefined') {
                    try {
                        await step.before('next');
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
                await process();
            }

            return Promise.resolve();
        },
        stop() {
            // this gets called by Finish, and we want to handle the case that something needs to happen on close anyway
            this.$emit('onafter', { action: this.validSteps[this.currentStep].onAfter });
            this.customCallbacks.onStop();
            document.body.classList.remove('v-tour--active');
            this.currentStep = -1;
        },
        skip() {
            this.customCallbacks.onSkip();
            this.stop();
        },
        finish() {
            this.customCallbacks.onFinish();
            this.stop();
        },

        handleKeyup(e) {
            if (this.customOptions.debug) {
                // eslint-disable-next-line no-console
                console.log('[Vue Tour] A keyup event occured:', e);
            }
            if (e.keyCode === KEYS.ARROW_RIGHT && this.isKeyEnabled('arrowRight')) {
                this.nextStep();
            }
            if (e.keyCode === KEYS.ARROW_LEFT && this.isKeyEnabled('arrowLeft')) {
                this.previousStep();
            }
            if (e.keyCode === KEYS.ESCAPE && this.isKeyEnabled('escape')) {
                this.stop();
            }
        },
        isKeyEnabled(key) {
            const { enabledNavigationKeys } = this.customOptions;
            return Object.prototype.hasOwnProperty.call(enabledNavigationKeys, key) ? enabledNavigationKeys[key] : true;
        },
    },
};
</script>

<style lang="scss">
body.v-tour--active {
    pointer-events: none;
    touch-action: none;
}
svg.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.4;
    z-index: 9999;
}
.v-tour {
    pointer-events: auto;
}

// .v-tour__target--highlighted {
//     box-shadow: 0 0 0 1em rgba(0,0,0,0), 0 0 0 99999px rgba(0,0,0,.4);
//     pointer-events: auto;
//     border-radius: $border-radius;
//     z-index: 9999;
// }

.v-tour__target--relative {
    position: relative;
}
</style>
