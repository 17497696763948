<template>
    <div
        class="mapboxgl-ctrl map-legend box is-family-primary has-text-weight-medium is-size-7"
    >
        <b-icon
            id="infoAbout"
            icon="information-outline"
            class="is-pulled-right"
            @mouseover.native="showTooltip"
            @mouseleave.native="hideTooltip"
        />
        <div
            ref="aboutTooltip"
            class="tooltip"
            role="tooltip"
        >
            <span v-text="$t('mapLegend.tooltipText')" />
            <div
                class="arrow"
                data-popper-arrow
            />
        </div>
        <div
            v-if="isMobile"
            @click="showLegend=!showLegend"
        >
            <b-icon
                :icon="showLegend ? 'chevron-down' : 'chevron-up'"
            />
        </div>
        <div
            v-if="showLegend"
        >
            <div
                v-if="dimensionScores === null"
                class="score-section"
            >
                <span class="left-side">{{ $t(`metrics.${metric.id}.subLayers.${metric.subMetric}`) }}</span>
                <span v-text="$t('mapLegend.selectLocationText')" />
            </div>
            <div
                v-if="dimensionScores !== null && myScore !== null"
                class="score-section"
            >
                <div
                    v-if="isMobile"
                    class="selected-location bold-blue"
                >
                    {{ address.firstLine }}
                </div>
                <div class="left-side mobile-right">
                    <span class="numerator">{{ myScore }}</span><span>/10</span>
                    <p>{{ $t(`metrics.${metric.id}.subLayers.${metric.subMetric}`) }}</p>
                </div>
                <div
                    v-if="!isMobile"
                    class="right-side"
                >
                    {{ $t('mapLegend.compareFirst') }}
                    <span class="bold-blue lower-case">{{ scoreString }}</span>
                    {{ $t(`metrics.${metric.id}.subLayers.${metric.subMetric}`) }}
                    <span v-if="myScore > 4 && myScore <= 6">{{ $t('mapLegend.compareAverage') }} </span>
                    <span v-else>{{ $t('mapLegend.compareLast') }} </span>
                    <span class="bold-blue">{{ $t('mapLegend.canadaText') }}</span>.
                </div>
            </div>
            <div
                :style="gradientCss"
                class="gradient"
            >
                <div
                    v-if="dimensionScores !== null && myScore !== null"
                    class="vl"
                />
                <div class="dl d1" />
                <div class="dl d2" />
                <div class="dl d3" />
                <div class="dl d4" />
            </div>

            <div class="gradient-labels">
                <span v-text="$t('mapLegend.muchWorseScore')" />
                <span v-text="$t('mapLegend.worseScore')" />
                <span v-text="$t('mapLegend.averageScore')" />
                <span v-text="$t('mapLegend.betterScore')" />
                <span v-text="$t('mapLegend.muchBetterScore')" />
            </div>
            <div
                v-if="isMobile && dimensionScores !== null && myScore !== null"
                class="right-side"
            >
                {{ $t('mapLegend.compareFirst') }}
                <span class="bold-blue lower-case">{{ scoreString }}</span>
                {{ $t(`metrics.${metric.id}.subLayers.${metric.subMetric}`) }}
                <span v-if="myScore > 4 && myScore <= 6">{{ $t('mapLegend.compareAverage') }} </span>
                <span v-else>{{ $t('mapLegend.compareLast') }} </span>
                <span class="bold-blue">{{ $t('mapLegend.canadaText') }}</span>.
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="dimensionScores !== null && myScore !== null && lngLat"
                class="text"
                v-html="$t('report.share.shareYourScore')"
            />
            <!-- eslint-enable vue/no-v-html -->
            <b-field
                v-if="dimensionScores !== null && myScore !== null && lngLat"
                class="row field has-addons"
            >
                <b-input
                    readonly
                    :value="reportUrl"
                />
                <p class="control">
                    <b-button
                        type="is-secondary"
                        @click="copyReportUrl"
                    >
                        {{ $t('report.share.copyLink') }}
                    </b-button>
                </p>
            </b-field>
        </div>
        <div
            v-else
            class="hidden-legend bold-blue"
        />
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
    name: 'MapLegend',

    props: {
        metric: {
            type: Object,
            default: () => ({
                id: null,
                name: null,
            }),
        },
        dimensionScores: {
            type: Object,
            default: () => {},
        },
        lngLat: {
            type: Object,
            default: () => {},
        },
        metrics: {
            type: Array,
            required: true,
        },
        colors: {
            type: Array,
            required: true,
        },
        address: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        tooltip: false,
        showLegend: true,
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }),

    computed: {
        scaleItems() {
            const subColor = this.colors.find(color => color.id === this.metric.subMetric);
            if (!subColor) return '';
            return `linear-gradient(to right, ${subColor.stops.join(', ')})`;
        },
        gradientCss() {
            // join the colourRamps into a gradient and return that as a string for css
            return {
                background: this.scaleItems,
            };
        },
        myScore() {
            if (this.dimensionScores === null || this.dimensionScores[this.metric.subMetric] === null) return null;
            document.documentElement.style.setProperty('--line-pos', `${this.dimensionScores[this.metric.subMetric] * 10}%`);
            return this.dimensionScores[this.metric.subMetric];
        },
        scoreString() {
            if (this.myScore > 8) return this.$t('mapLegend.muchBetterScore');
            if (this.myScore > 6) return this.$t('mapLegend.betterScore');
            if (this.myScore > 4) return this.$t('mapLegend.averageScore');
            if (this.myScore > 2) return this.$t('mapLegend.worseScore');
            return this.$t('mapLegend.muchWorseScore');
        },
        reportUrl() {
            const params = new URLSearchParams();
            params.set('lat', this.lngLat.lat);
            params.set('lng', this.lngLat.lng);
            params.set('shared', true);
            if (this.address) {
                params.set('addressFirstLine', this.address.firstLine);
                params.set('addressSecondLine', this.address.secondLine);
            }

            const { origin } = window.location;
            const { pathname } = window.location;

            return `${origin}${pathname}?${params.toString()}`;
        },
    },

    mounted() {
        this.tooltip = createPopper(
            document.querySelector('#infoAbout'),
            this.$refs.aboutTooltip,
            {
                placement: 'left',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            },
        );
    },

    methods: {
        /**
        * Copy the report url to the clipboard.
        *
        * To avoid messing with the real input, we accomplish this by adding an element containing the url to the dom,
        * copying its value, and then removing the element.
        */
        copyReportUrl() {
            const el = document.createElement('textarea');
            el.value = this.reportUrl;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.$buefy.snackbar.open({
                message: this.$t('notifications.linkCopied'),
            });

            // track analytics event
            window.gtag('event', 'share-button-click', { action: 'Button' });
        },

        showTooltip() {
            this.$refs.aboutTooltip.setAttribute('data-show', '');
            this.tooltip.setOptions(options => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: true },
                ],
            }));
        },

        hideTooltip() {
            this.$refs.aboutTooltip.removeAttribute('data-show');
            this.tooltip.setOptions(options => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: false },
                ],
            }));
        },
    },
};
</script>

<style lang="scss">
:root {
    --line-pos: 90%;
}

.map-legend {
    width: 14rem;
    padding: .5rem .75rem;
    @include tablet {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 27rem;
    }

    @include mobile {
        width: 100vw;
        border-radius: 0;
    }
}

.hidden-legend {
    width: 93vw;
}

.score-section {
    display: flex;
    .selected-location {
        flex: 1;
        font-size: 1.5em;
        margin: auto;
    }
    .left-side {
        flex: 1;
        font-size: 1.5em;
        font-weight: bold;
        .numerator {
            font-size: 2em;
        }
    }
    .mobile-right {
        @include mobile {
            text-align: right;
        }
    }
    padding-bottom: 1em;
}

.bold-blue {
    font-weight: bold;
    color: $primary;
}

.right-side {
    flex: 1;
    @include desktop {
        font-size: 1.3em;
        margin-left: 2em;
    }
    @include mobile {
        margin-bottom: 1em;
    }

    .lower-case {
        text-transform: lowercase;
    }
}

.gradient {
    height: 2em;
    border-radius: 1em;
    position: relative;

    .vl {
        border-left: 8px solid black;
        border-radius: 1em;
        height: 3em;
        left: var(--line-pos);
        margin-left: -4px;
        position: absolute;
        top: -0.5em;
        z-index: 1;
    }
    .dl {
        border-left: 1px solid white !important;
        height: 2em;
        position: absolute;
        top: 0;

        &.d1 { left: 20%; }
        &.d2 { left: 40%; }
        &.d3 { left: 60%; }
        &.d4 { left: 80%; }
    }
}

.gradient-labels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    span {
        padding: 0.3rem 0.3rem;
        text-align: center;
    }

    span:first-child {
        text-align: left;
    }

    span:last-child {
        text-align: right;
    }
}

.tooltip {
    background: #000;
    border-radius: 10px;
    color: white;
    padding: 0.5rem;
    max-width: 15vw;
    display: none;
    z-index: 40;

    @include mobile {
        max-width: 50vw;
    }


    &[data-show] {
        display: block;
    }

    .arrow,
    .arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    .arrow {
        visibility: hidden;
    }

    .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    &[data-popper-placement^='top'] > .arrow {
        bottom: -3px;
    }
    &[data-popper-placement^='bottom'] > .arrow {
        top: -3px;
    }
    &[data-popper-placement^='left'] > .arrow {
        right: -3px;
    }
    &[data-popper-placement^='right'] > .arrow {
        left: -3px;
    }
}
</style>
