<template>
    <div
        id="community-search"
        class="community-search"
    >
        <div
            v-if="isMobile"
            @click="$emit('close')"
        >
            <b-icon
                icon="close"
                class="is-pulled-right"
                pack="mdi"
            />
        </div>
        <p class="has-text-weight-bold">
            {{ $t('report.whatsNext.communityPlanning.button') }}
        </p>
        <p>{{ this.$t('report.whatsNext.communityPlanning.prompt') }}</p>
        <b-field grouped>
            <b-input
                v-model="searchCommunities"
                expanded
                :placeholder="this.$t('report.whatsNext.communityPlanning.placeholder')"
                type="text"
            />
            <div
                v-if="!isMobile"
                class="control"
            >
                <b-button
                    type="is-primary"
                    :loading="loading"
                    :disabled="searchCommunities === ''"
                    @click="e => {
                        onConfirm(
                            searchCommunities,
                            this.$t('report.whatsNext.communityPlanning.searchQuery')
                        )
                    }"
                >
                    {{ this.$t('report.whatsNext.communityPlanning.ok') }}
                </b-button>
            </div>
        </b-field>
        <div
            v-if="isMobile"
            class="control"
        >
            <b-button
                type="is-primary"
                :loading="loading"
                :disabled="searchCommunities === ''"
                @click="e => {
                    onConfirm(
                        searchCommunities,
                        this.$t('report.whatsNext.communityPlanning.searchQuery')
                    )
                }"
            >
                {{ this.$t('report.whatsNext.communityPlanning.ok') }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        searchCommunities: '',
        loading: false,
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }),

    methods: {
        onConfirm: (inputLocation, localizedSearchQuery) => {
            // use props for location
            const searchQuery = `${inputLocation} ${localizedSearchQuery}`;
            const searchParams = new URLSearchParams({ q: searchQuery });
            const searchUrl = `https://www.google.com/search?${searchParams}`;
            window.open(searchUrl, '_blank');

            // track analytics event
            window.gtag('event', 'community-plan-submit', { action: 'Link', label: `${inputLocation}` });
        },
    },
};
</script>

<style lang="scss">
.community-search {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $light;

    @include desktop {
        margin: 1rem;
    }

    @include mobile {
        padding: 1rem 0.5rem 1rem 0.5rem;
        text-align: center;

        p {
            color: $primary;
            padding: 0.2em 0 0.2em;
        }
    }
}
</style>
