<template lang="html">
    <b-message
        class="suggested-pois"
        :title="$t('suggestedPois.header')"
        type="is-warning"
        has-icon
        aria-close-label="Close message"
        @close="$emit('close')"
    >
        <p
            class="content"
            v-text="$t('suggestedPois.intro')"
        />

        <ul>
            <li
                v-for="poi in pois"
                :key="poi.name"
                class="has-text-weight-semibold poi-suggestion"
                @click="goToPoi(poi)"
            >
                <b-icon
                    icon="map-marker-outline"
                    size="is-small"
                />
                {{ poi.title }}
                <small
                    class="has-text-weight-normal has-text-grey-light"
                    v-text="poi.location_text"
                />
            </li>
        </ul>
    </b-message>
</template>

<script>
import { LngLat } from 'mapbox-gl';

export default {
    name: 'SuggestedPois',

    data() {
        return {
            pois: this.$t('suggestedPois.pois'),
        };
    },

    methods: {
        /**
         * emit a "coords" event for the POI coordinates and close the modal
         * @param  {mapboxgl.LngLat} lngLat POI coordinates
         */
        goToPoi(pointOfInterest) {
            this.$emit(
                'location',
                new LngLat(pointOfInterest.location.longitude, pointOfInterest.location.latitude),
                {
                    firstLine: pointOfInterest.title,
                    secondLine: pointOfInterest.location_text,
                },
            );
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.suggested-pois {
    .poi-suggestion {
        padding: .2em .3em;
        cursor: pointer;

        &:hover {
            background: $primary;
            color: $primary-invert;

            small {
                color: $white-ter !important;
            }
        }
    }
}
</style>
