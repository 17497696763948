import Vue from 'vue';
import Buefy from 'buefy';
import PrismicVue from '@prismicio/vue';
import { endpoint, accessToken, linkResolver } from '@/cms';
import { i18n } from '@/lang';
import App from './App.vue';
import router from './router';

// add Prismic plugin
Vue.use(PrismicVue, {
    endpoint,
    apiOptions: { accessToken },
    linkResolver,
});
// add buefy plugin
Vue.use(Buefy, {
    customIconPacks: {
        // use maki icon font with b-icon
        maki: {
            iconPrefix: 'mki mki-',
        },
    },
});

Vue.config.productionTip = false;

new Vue({
    i18n, // add vue-i18n plugin
    router,
    render: h => h(App),
}).$mount('#app');
