<template>
    <div
        v-show="show"
        id="survey-prompt"
        class="survey-prompt"
        @click="$emit('show-survey')"
    >
        <b-icon
            type="is-primary"
            size="is-medium"
            pack="mdi"
            icon="clipboard-outline"
        />
        <p class="desktop-text has-text-weight-bold">
            {{ $t('survey.popupTitle') }}
        </p>
        <p class="desktop-text">
            {{ $t('survey.popupText') }}
        </p>
        <p class="mobile-text">
            {{ $t('survey.mobileTitle') }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'SurveyPrompt',

    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss">
.survey-prompt {
    background-color: $white;
    color: $primary;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    $navbar-height: 3.25rem;
    padding: 0.5rem;
    text-align: center;
    z-index: 6;
    pointer-events: all;

    border-radius: 50%;
    height: 5em;
    width: 5em;

    @include desktop {
        border-radius: 0.5rem;
        height: auto;
        right: 1em;
        width: calc(25vw - 7rem);
    }

    @include mobile {
        margin-bottom: 1rem;
        height: 3.5rem;
        width: 3.5rem;
        .icon {
            height: 1rem;
            width: 1rem;
            line-height: 1rem;
            .mdi-36px.mdi::before {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }
}

.desktop-text {
    @include mobile {
        display: none;
    }
}
.mobile-text {
    @include desktop {
        display: none;
    }
    font-size: 0.8rem;
    line-height: 0.8rem;
}
</style>
