// Prismic CMS configuration

/**
 * Returns a link path for a Prismic document object.
 *
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 *
 * @param {object} doc - Prismic document
 * @returns {string} - link path
 */
export function linkResolver(doc) {
    // parse "en" or "fr" from "en-ca" / "fr-ca"
    const [lang] = doc.lang.split('-');

    // special hardcoded "page" type pages
    if (doc.uid === 'our-research') {
        return `/${lang}/our-research`;
    }

    if (doc.uid === 'methods') {
        return `/${lang}/methods`;
    }

    // fallback: send anything else to the homepage
    return `/${lang}`;
}

export const endpoint = process.env.VUE_APP_PRISMIC_CMS_ENDPOINT;

export const accessToken = ''; // leave empty for a public API
