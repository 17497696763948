<template>
    <div class="email-container">
        <div
            v-if="isMobile"
            @click="$emit('close')"
        >
            <b-icon
                icon="close"
                class="is-pulled-right"
                pack="mdi"
            />
        </div>
        <div
            v-if="isMobile"
            class="logo-container pb-2"
        >
            <img
                :src="$t('userAgreement.logo.url')"
                class="logo"
            >
        </div>
        <span class="email-title">
            {{ $t('report.subscribe.emailInputHeader') }}
        </span>
        <p
            v-if="isMobile"
            class="email-message"
        >
            {{ $t('report.subscribe.emailInputMessage') }}
        </p>
        <b-field grouped>
            <b-input
                v-model="subscribeEmail"
                expanded
                :placeholder="$t('report.subscribe.emailInputPlaceholder')"
                type="email"
            />
            <div
                v-if="!isMobile"
                class="control"
            >
                <b-button
                    type="is-primary"
                    :loading="loading"
                    :disabled="subscribeEmail === ''"
                    @click="addEmailSubscription"
                >
                    {{ $t('report.subscribe.subscribe') }}
                </b-button>
            </div>
        </b-field>
        <div
            v-if="isMobile"
            class="control"
        >
            <b-button
                type="is-primary"
                :loading="loading"
                :disabled="subscribeEmail === ''"
                @click="addEmailSubscription"
            >
                {{ $t('report.subscribe.subscribe') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {
    API_URL,
} from '@/constants';
import EventBus from '@/eventBus';

export default {
    name: 'EmailSubscribe',

    data: () => ({
        subscribeEmail: '',
        loading: false,
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }),

    methods: {
        /**
         * Attempt to add an email to the Mailchimp audience
         * via the /subscribe API endpoint
         * and show a message on success or failure
         */
        async addEmailSubscription() {
            try {
                this.loading = true;

                const response = await axios.post(`${API_URL}/subscribe`, {
                    email: this.subscribeEmail,
                });

                if (response.data.statusCode !== 200) {
                    throw new Error('Problem subscribing email');
                }

                this.$buefy.snackbar.open({
                    message: this.$t('notifications.emailSubscribeSuccess'),
                });

                // track analytics event
                window.gtag('event', 'subscribe-button-click', { action: 'Button' });
            } catch (error) {
                EventBus.$emit('error', error, this.$t('errors.emailSubscribe'));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.email-container {
    @include mobile {
        text-align: center;
        background-color: white;
        padding: 1em;
    }
}
.email-title {
    @include mobile {
        color: $primary;
        font-weight: bold;
    }
}
.email-message {
    color: $primary;
    padding: 0.7em;
}
</style>
