<template>
    <router-view />
</template>

<script>
import { setI18nLanguage } from '@/lang';

export default {
    name: 'LocaleRouter',

    watch: {
        // update locale when route changes
        $route: {
            immediate: true,
            handler(newRoute) {
                const newLocale = newRoute.params.locale;
                setI18nLanguage(newLocale);
            },
        },

        // update route parameter when locale changes
        '$i18n.locale': {
            immediate: true,
            handler(newLocale) {
                if (this.$route.params.locale !== newLocale) {
                    this.$router.push({
                        name: this.$route.name,
                        params: {
                            locale: newLocale,
                        },
                    });
                }

                // update the document "lang" attribute
                setI18nLanguage(newLocale);

                // emit load event to the parent (App.vue root component)
                this.$emit('i18n-load');
            },
        },
    },
};
</script>
