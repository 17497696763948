// ids & metadata for the score metrics to be used throughout the app
// fieldName = database column name / CMS field name / i18n translation key, etc.

class MetricsCollection {
    #metricsArray = [];

    constructor(metrics = []) {
        this.metricsArray = metrics;
    }

    get metrics() {
        return this.metricsArray;
    }

    /**
     * Populates the internal list of score metrics.
     * @param {Array} newVal - formatted array of score metrics, with map subLayers
     * @param {boolean} [force=false] - if true, always repopulate the list (default: skip repopulating)
     */
    setMetrics(newVal, force = false) {
        // don't re-populate the list unless forced
        if (this.metrics.length > 0 && force === false) return;

        this.metricsArray = newVal.map((metric) => {
            const mapSubLayers = metric.data.map_sublayers.map(subLayer => ({
                fieldName: subLayer.layer_id,
                layerId: subLayer.layer_id,
                visual: String(subLayer.visual_type).toLowerCase(),
                icon: subLayer.icon_name,
                color: subLayer.fill_color,
                outlineColor: subLayer.outline_color,
            }));

            // this metric map layer's color scale for the map legend
            const legendColors = metric.data.map_legend_colors.map(i => i.color);

            return {
                fieldName: metric.data.map_layer_id,
                icon: metric.data.icon,
                mapSubLayers,
                legendColors,
                initialSelection: metric.data.initially_selected,
            };
        });
    }
}

const metricsCollection = new MetricsCollection();

export default metricsCollection;
