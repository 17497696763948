var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"community-search",attrs:{"id":"community-search"}},[(_vm.isMobile)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"is-pulled-right",attrs:{"icon":"close","pack":"mdi"}})],1):_vm._e(),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('report.whatsNext.communityPlanning.button'))+" ")]),_c('p',[_vm._v(_vm._s(this.$t('report.whatsNext.communityPlanning.prompt')))]),_c('b-field',{attrs:{"grouped":""}},[_c('b-input',{attrs:{"expanded":"","placeholder":this.$t('report.whatsNext.communityPlanning.placeholder'),"type":"text"},model:{value:(_vm.searchCommunities),callback:function ($$v) {_vm.searchCommunities=$$v},expression:"searchCommunities"}}),(!_vm.isMobile)?_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.loading,"disabled":_vm.searchCommunities === ''},on:{"click":function (e) {
                    _vm.onConfirm(
                        _vm.searchCommunities,
                        this$1.$t('report.whatsNext.communityPlanning.searchQuery')
                    )
                }}},[_vm._v(" "+_vm._s(this.$t('report.whatsNext.communityPlanning.ok'))+" ")])],1):_vm._e()],1),(_vm.isMobile)?_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.loading,"disabled":_vm.searchCommunities === ''},on:{"click":function (e) {
                _vm.onConfirm(
                    _vm.searchCommunities,
                    this$1.$t('report.whatsNext.communityPlanning.searchQuery')
                )
            }}},[_vm._v(" "+_vm._s(this.$t('report.whatsNext.communityPlanning.ok'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }