<template>
    <header id="header">
        <router-link
            class="is-flex is-align-items-center"
            :to="{ name: 'home' }"
        >
            <img
                :src="$t('userAgreement.logo.url')"
                class="logo"
            >
        </router-link>
    </header>
</template>

<script>
export default {
    name: 'PageHeader',
};
</script>

<style lang="scss">
#header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .logo {
        margin: 0.75rem;
        width: 10rem;
    }

    .locale-switcher {
        margin-right: 0.75rem;
    }
}
</style>
