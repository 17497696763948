<template lang="html">
    <div
        :id="id"
        class="mapbox-gl-map-component has-background-grey-lighter"
    />
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
    name: 'MapboxGlMap',

    props: {
        id: {
            type: String,
            required: true,
        },
        accessToken: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        disableRotate: {
            type: Boolean,
            default: true,
        },
        // check a list of source ids when `sourcedata` fires
        // emit `allsourcesready` event when ALL of them are loaded
        checkSourceIds: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            map: null,
        };
    },

    watch: {
        // update the map when the i18n locale changes
        '$i18n.locale': function handler(locale) {
            this.changeMapLanguage(locale);
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        /**
         * Initializes the map and plugins.
         */
        init() {
            mapboxgl.accessToken = this.accessToken;
            this.map = new mapboxgl.Map(Object.assign({
                container: this.id,
                style: 'mapbox://styles/mapbox/streets-v9',
                minZoom: 1,
            }, this.options));

            if (this.disableRotate) {
                // disable map rotation using right click + drag
                this.map.dragRotate.disable();
                // disable map rotation using touch rotation gesture
                this.map.touchZoomRotate.disableRotation();
            }

            // add navigation controls
            this.map.addControl(new mapboxgl.NavigationControl({
                // hide rotation control
                showCompass: !this.disableRotate,
            }), 'bottom-right');
            // add scale control
            this.map.addControl(new mapboxgl.ScaleControl({
                maxWidth: 75,
            }));

            // emit 'loaded' event
            this.map.on('load', (loadEvent) => {
                this.$emit('loaded', this.map, loadEvent);
            });

            // fire `allsourcesready` event when all user-defined sources area loaded
            this.map.on('sourcedata', (loadEvent) => {
                const ids = this.checkSourceIds;
                if (!ids.every(id => this.map.getSource(id))) {
                    return;
                }

                if (!ids.every(id => this.map.isSourceLoaded(id))) {
                    return;
                }

                this.$emit('allsourcesready', loadEvent);
            });

            this.map.on('click', (ev) => {
                this.$emit('click', ev);
            });

            // emit 'moveend' event
            this.map.on('moveend', (moveEvent) => {
                this.$emit('moveend', moveEvent);
            });

            // initial language setting
            this.map.once('load', this.changeMapLanguage);
        },

        /**
         * Updates the map label language.
         */
        changeMapLanguage() {
            // skip if the plugin isn't loaded yet
            if (!this.map) return;

            const language = this.$i18n.locale; // "en" or "fr"

            // update country labels layer
            this.map.setLayoutProperty('country-label', 'text-field', [
                'get',
                `name_${language}`,
            ]);
        },
    },
};
</script>

<style lang="scss">
</style>
